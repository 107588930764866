import React, { ReactNode, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { PMT_AGE_MAX, PMT_DAYS_OVERDUE_MAX, PMT_DAYS_OVERDUE_MIN } from '../../../../util/constants';
import { Rule } from '../../../../domain/ScheduledMessaging';
import { PatientStatusChip } from '../PatientStatusChip/PatientStatusChip';
import { useFrontendDefinitions } from '../../../../hooks/useFrontendDefinitions/useFrontendDefinitions';
import { useClinicians } from '../../../../hooks/useClinicians/useClinicians';
import Box from '@mui/material/Box';
import { SectionSpinner } from '../../../shared/LoadingSection/LoadingSection';

interface Props {
  rule: Rule;
}

function Value({ children }: { children: ReactNode }) {
  return (
    <Typography
      component="span"
      color="text.primary"
      variant="subtitle2"
      fontWeight="normal"
      sx={{ textDecoration: 'underline dotted' }}
    >
      {children}
    </Typography>
  );
}

function SimpleListItem({ title, text, children }: { title: string; text?: string; children?: ReactNode }) {
  return (
    <ListSubheader sx={{ lineHeight: '32px' }}>
      <Stack direction="row" alignItems="center" gap={1}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <CheckBoxOutlinedIcon sx={{ fontSize: '16px' }} />
          {title}
        </Stack>
        {text ? <Value>{text}</Value> : children}
      </Stack>
    </ListSubheader>
  );
}

interface MultilineItem { key: string; value?: string }

function MultilineListItem({ title, items }: { title: string; items: MultilineItem[] }) {
  return (
    <>
      <ListSubheader sx={{ lineHeight: '32px' }}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <CheckBoxOutlinedIcon sx={{ fontSize: '16px' }} />
          {title}
        </Stack>
      </ListSubheader>
      {items.map((item) => (
        <ListItem disableGutters disablePadding key={item.key}>
          <ListItemText
            primary={
              <>
                <Typography color="text.primary" variant="subtitle2" fontWeight="normal">
                  <Value>{item.value ?? item.key}</Value>
                </Typography>
              </>
            }
            sx={{ margin: 0, paddingLeft: '40px' }}
          />
        </ListItem>
      ))}
    </>
  );
}

function getUniqValueItems(keys: string[] | undefined, getter: (key: string) => string | undefined): MultilineItem[] {
  if (!keys) return [];

  const unique = new Set<string>();

  const result: MultilineItem[] = [];

  keys.forEach((key) => {
    const value = getter(key);

    if (!value) {
      result.push({ key });

      return;
    }

    if (unique.has(value)) {
      return;
    }

    unique.add(value);

    result.push({ key, value });
  });

  return result;
}

function translateSorting(sortBy: Rule['sortBy']):
  | {
      column: string;
      order: 'ASC' | 'DESC';
    }
  | undefined {
  if (!sortBy) {
    return undefined;
  }

  const { column, order } = sortBy;

  return column === 'nhs_number'
    ? { column: 'NHS Number', order }
    : column === 'status'
      ? { column: 'Status', order }
      : column === 'days_overdue'
        ? { column: 'Days Overdue', order }
        : { column: 'Age', order: order === 'ASC' ? 'DESC' : 'ASC' };
}

export function RuleDefinition({ rule }: Props) {
  const { frontendDefinitions, loading: frontendDefinitionsLoading } = useFrontendDefinitions();
  const { clinicians, loading: cliniciansLoading } = useClinicians();

  const { action_props: actionProps, indication_props: indicationProps } = frontendDefinitions?.data.app_info ?? {
    action_props: {},
    indication_props: {},
  };

  const sortBy = translateSorting(rule.sortBy);

  const indicationItems = useMemo(
    () => getUniqValueItems(rule.indications, (id) => indicationProps?.[id]?.NameText),
    [rule.indications, indicationProps],
  );

  const excludedIndicationItems = useMemo(
    () => getUniqValueItems(rule.excludedIndications, (id) => indicationProps?.[id]?.NameText),
    [rule.excludedIndications, indicationProps],
  );

  const taskItems = useMemo(
    () => getUniqValueItems(rule.actions, (id) => actionProps?.[id]?.ActionName),
    [rule.actions, actionProps],
  );

  if (frontendDefinitionsLoading || cliniciansLoading) {
    return <SectionSpinner />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography>This rule looks for patients matching:</Typography>
      <List sx={{ ml: -2, p: 0 }}>
        {rule?.indications && <MultilineListItem title="Indications (any of):" items={indicationItems} />}

        {rule?.excludedIndications && (
          <MultilineListItem title="Exclusions (any of):" items={excludedIndicationItems} />
        )}

        {rule?.actions && <MultilineListItem title="Actions (any of):" items={taskItems} />}

        {rule?.statuses && (
          <SimpleListItem title="Status:">
            <Stack direction="row" gap={1}>
              {rule.statuses.map((status) => (
                <PatientStatusChip key={status} status={status} />
              ))}
            </Stack>
          </SimpleListItem>
        )}

        {rule?.monthOfBirth && <SimpleListItem title="Month of Birth:" text={rule.monthOfBirth} />}

        {rule?.useCurrentMonthAsDob && <SimpleListItem title="Dynamic birth month:" text="Current month" />}

        {rule?.useNextMonthAsDob && <SimpleListItem title="Dynamic birth month:" text="Next month" />}

        {(() => {
          if (!rule?.daysOverdue) return null;

          const { from, to } = rule.daysOverdue;

          const text: ReactNode =
            from === to ? (
              <>
                exactly <Value>{from}</Value> day
                {from === 1 ? '' : 's'}
              </>
            ) : (
              <>
                between <Value>{from < PMT_DAYS_OVERDUE_MIN ? 'Min' : from}</Value> and{' '}
                <Value>{to > PMT_DAYS_OVERDUE_MAX ? 'Max' : to}</Value> day
                {to === 1 ? '' : 's'}
              </>
            );

          return (
            <SimpleListItem title="Days overdue:">
              <Typography variant="subtitle2" color="text.primary" fontWeight="normal">
                {text}
              </Typography>
            </SimpleListItem>
          );
        })()}

        {(() => {
          if (!rule?.age) return null;

          const { from, to } = rule.age;

          const text: ReactNode =
            from === to ? (
              <>
                exactly <Value>{from}</Value> year
                {from === 1 ? '' : 's'} old
              </>
            ) : (
              <>
                between <Value>{from}</Value> and <Value>{to > PMT_AGE_MAX ? 'Max' : to}</Value> year
                {to === 1 ? '' : 's'} old
              </>
            );

          return (
            <SimpleListItem title="Age:">
              <Typography variant="subtitle2" color="text.primary" fontWeight="normal">
                {text}
              </Typography>
            </SimpleListItem>
          );
        })()}

        {rule?.gpIds && (
          <MultilineListItem
            title="GPs (any of):"
            items={rule.gpIds.map((id) => ({ key: id, value: clinicians?.[id] ?? id }))}
          />
        )}
      </List>

      {sortBy && (
        <Typography>
          and then sorts the patients by <Value>{sortBy.column}</Value>, in{' '}
          <Value>{sortBy.order === 'ASC' ? 'ascending' : 'descending'}</Value> order
        </Typography>
      )}
    </Box>
  );
}
