import React, { useEffect, useState } from 'react';
import * as Api from '../../apis/website/Api';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DownloadUrls } from '../../domain/DownloadUrls';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import SidePanel from '../shared/SidePanel/SidePanel';
import { LoadingSection } from '../shared/LoadingSection/LoadingSection';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification } from '../../redux/reducers/notificationsReducer';

function DownloadItem({ label, url }: { label: string; url: string }) {
  return (
    <ListItem disablePadding>
      <ListItemButton component="a" href={url}>
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
}

function DownloadItemDisabled({ label }: { label: string }) {
  return (
    <ListItem disablePadding>
      <ListItemButton disabled>
        <ListItemIcon>
          <FileDownloadOffIcon />
        </ListItemIcon>
        <ListItemText
          primary={label}
          secondary="Could not find the latest version"
          slotProps={{
            secondary: { fontStyle: 'italic' },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default function DownloadsPanel() {
  const [downloadUrls, setDownloadUrls] = useState<DownloadUrls>({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    Api.getDownloadUrls()
      .then(setDownloadUrls)
      .catch((error) => {
        dispatch(enqueueErrorNotification('Unable to find the latest release of the tool', error));
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <SidePanel title="Downloads">
      <LoadingSection loading={loading}>
        <List>
          {downloadUrls.emis ? (
            <DownloadItem label="Proactive Monitoring Tool (EMIS Web)" url={downloadUrls.emis} />
          ) : (
            <DownloadItemDisabled label="Proactive Monitoring Tool (EMIS Web)" />
          )}
          {downloadUrls.tpp ? (
            <DownloadItem label="Proactive Monitoring Tool (TPP SystmOne)" url={downloadUrls.tpp} />
          ) : (
            <DownloadItemDisabled label="Proactive Monitoring Tool (TPP SystmOne)" />
          )}
        </List>
      </LoadingSection>
    </SidePanel>
  );
}
