import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridRowParams,
  GridRowId,
  FooterPropsOverrides,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import { useBulkMessageReports } from '../../../../hooks/useBulkMessageReports/useBulkMessageReports';
import { ReportSummary } from '../ReportSummary/ReportSummary';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';

declare module '@mui/x-data-grid' {
  interface FooterPropsOverrides {
    loading?: boolean;
    error?: unknown;
    rowCount: number;
    hasMore: boolean;
  }
}

export interface Props {
  rows: GridRowsProp;
  cursor: string | null | undefined;
  loading?: boolean;
  error?: unknown;
}

const PAGE_SIZE = 25;

function InfiniteFooter({ loading, rowCount, hasMore, error }: FooterPropsOverrides) {
  return (
    <Box p={2} textAlign="right">
      {loading || error ? (
        <></>
      ) : (
        <>
          {rowCount} report{rowCount === 1 ? '' : 's'} loaded.{' '}
          {hasMore ? <>Keep scrolling for more</> : <>No more reports!</>}
        </>
      )}
    </Box>
  );
}

export function BulkMessageReportsDataGrid({ senderFilter }: { senderFilter?: string }) {
  const [openRowId, setOpenRowId] = useState<GridRowId | null>(null);

  const dispatch = useDispatch();

  const columns: GridColDef[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      sortable: false,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      editable: false,
      resizable: true,
      sortable: false,
      valueFormatter: (value) => dayjs(value).format('ddd DD/MM/YYYY HH:mm'),
      renderCell: ({ formattedValue }) => (
        <Typography variant="body2" fontFamily="monospace" component="span">
          {formattedValue}
        </Typography>
      ),
    },
    {
      field: 'sender',
      headerName: 'Sender',
      width: 250,
      editable: false,
      resizable: true,
      sortable: false,
    },
  ];

  const { reports, loading, error, cursor, fetchMore } = useBulkMessageReports({
    senderFilter,
    limit: senderFilter ? undefined : PAGE_SIZE,
  });

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load Bulk Message Reports', error));
    }
  }, [error]);

  const rows =
    reports?.map((report) => ({
      id: report.reportId,
      date: report.timestamp,
      sender: report.sender,
    })) ?? [];

  return (
    <Box height={'calc(100vh - 400px)'}>
      <DataGridPro
        sx={{
          '.MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },

          '.MuiDataGrid-cell:hover, .MuiDataGrid-cell:hover *': {
            cursor: 'pointer',
          },
        }}
        rows={rows}
        columns={columns}
        getDetailPanelContent={useCallback(({ row }) => {
          return <ReportSummary reportId={row.id} />;
        }, [])}
        getDetailPanelHeight={useCallback((row: GridRowParams) => (row.id === openRowId ? 'auto' : 0), [openRowId])}
        detailPanelExpandedRowIds={openRowId ? [openRowId] : []}
        onRowClick={({ row }) => setOpenRowId(openRowId === row.id ? null : row.id)}
        onRowsScrollEnd={() => fetchMore()}
        disableColumnMenu
        loading={loading}
        slots={{
          footer: InfiniteFooter,
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'linear-progress',
          },
          footer: {
            loading,
            rowCount: rows.length,
            hasMore: !!cursor,
            error,
          },
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field]: false,
            },
          },
        }}
      />
    </Box>
  );
}
