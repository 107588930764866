import React from 'react';
import Checkbox from '@mui/material/Checkbox';

interface Props {
  value: string | boolean;
}

export const ReadOnlyCheckbox: React.FC<Props> = ({ value }) => {
  return <Checkbox checked={Boolean(value)} disabled />;
};
