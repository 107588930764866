import MuiTextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { Control, Controller, ControllerProps, FieldError, FieldValues } from 'react-hook-form';

export interface Props {
  name: string;
  control: Control<FieldValues>;
  error?: FieldError;
  rules: ControllerProps['rules'] & { invalid?: string };
}

export default function TextField({
  name,
  control,
  error,
  rules,
  defaultValue,
  ...inputProps
}: Omit<TextFieldProps, 'error'> & Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <MuiTextField
          onChange={onChange}
          value={value}
          error={!!error?.type}
          helperText={error?.message}
          {...inputProps}
        />
      )}
      rules={rules}
      defaultValue={defaultValue ?? ''}
    />
  );
}
