import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import React from 'react';
import TextField, { Props as TextFieldsProps } from '../TextField/TextField';
import { ControllerProps, ValidationRule } from 'react-hook-form';

type Props = Omit<TextFieldsProps, 'rules'> & {
  rules: ControllerProps['rules'] & { pattern?: ValidationRule<RegExp> };
};

export default function PasswordField({ ...props }: Props & Omit<MuiTextFieldProps, 'error'>) {
  return <TextField type="password" {...props} />;
}
