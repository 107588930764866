import { useMatch } from 'react-router';
import { useOrganisationDetails } from '../useOrganisationDetails/useOrganisationDetails';
import { OrganisationDetails } from '../../domain/Organisation';
import { updateContractExpiryDate, updateEhr } from '../../apis/app/AppApi';

import type { Ehr } from '../../apis/app/AppApi';

interface UseCurrentOrganisationDetailsResult {
  organisation: OrganisationDetails;
  loading: boolean;
  refresh: () => void;
  updateContractExpiryDate: (newDate: string) => Promise<unknown>;
  updateEhr: (ehr: Ehr) => Promise<unknown>;
}

export function useCurrentOrganisationDetails(): UseCurrentOrganisationDetailsResult {
  const {
    params: { name: organisation },
  } = useMatch('/organisations/:name')!;

  const { organisations, loading, refreshOrganisation } = useOrganisationDetails([organisation!]);

  return {
    organisation: organisations?.[organisation!] ?? ({ organisation } as OrganisationDetails),
    loading,
    refresh: () => refreshOrganisation(organisation!),
    updateContractExpiryDate: async (newDate: string) => {
      await updateContractExpiryDate(organisation!, newDate);
      refreshOrganisation(organisation!);
    },
    updateEhr: async (ehr: Ehr) => {
      await updateEhr(organisation!, ehr);
      refreshOrganisation(organisation!);
    },
  };
}
