import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../redux/reducers/notificationsReducer';
import { useQueryClient } from '@tanstack/react-query';
import { updatePopulationTabBeta } from '../../apis/app/AppApi';
import { QUERY_STRING_ORGANISATION_DETAILS } from '../../util/constants';
import { OrgDetails } from './helpers';

interface Props {
  organisationId: string;
  value: boolean;
}

export const PopulationTabBetaCheckbox: React.FC<Props> = ({ organisationId, value }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = event.target.checked;

    try {
      await updatePopulationTabBeta(organisationId, updatedValue);

      queryClient.setQueryData([QUERY_STRING_ORGANISATION_DETAILS], (previousConfig: OrgDetails[]) => {
        return previousConfig.map((org: OrgDetails) =>
          org.id === organisationId ? { ...org, populationTabBetaVisible: updatedValue } : org,
        );
      });
      dispatch(enqueueInfoNotification('Population tab beta configuration updated'));
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    }
  };

  return <Checkbox checked={value} onChange={onChange} />;
};
