import React, { useContext, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { RuleConfigurationTab } from '../RuleConfigurationTab/RuleConfigurationTab';
import { RuleExecutionsTab } from '../RuleExecutionsTab/RuleExecutionsTab';
import RulesContext from '../../../../context/RulesContext/RulesContext';
import Alert from '@mui/material/Alert';
import { RuleOverviewTab } from '../RuleOverviewTab/RuleOverviewTab';
import Typography from '@mui/material/Typography';
import { RuleAnalyticsTab } from '../RuleAnalyticsTab/RuleAnalyticsTab';
import ConfigContext from '../../../../context/ConfigContext';

export function RuleDetails() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_event: React.ChangeEvent<unknown>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const { selectedRule, hasUnsavedChanges } = useContext(RulesContext);

  const ruleAnalyticsEnabled = useContext(ConfigContext).features?.ruleAnalytics === true;

  useEffect(() => {
    setSelectedTab(0);
  }, [selectedRule?.id]);

  if (!selectedRule) {
    return (
      <Alert severity="warning" sx={{ alignSelf: 'start' }}>
        Please select a rule on the left to see details
      </Alert>
    );
  }

  return (
    <Box position="relative" display="flex" flexDirection="column" gap={3} width={600}>
      <Typography
        variant="h6"
        position="sticky"
        top={0}
        zIndex={3}
        mb={-1}
        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.9)' }}
      >
        {selectedRule.definition.name}
      </Typography>

      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="Overview" disabled={!selectedRule || hasUnsavedChanges} />
        <Tab label="Configuration" disabled={!selectedRule} />
        <Tab label="Executions" disabled={!selectedRule || hasUnsavedChanges} />
        {ruleAnalyticsEnabled && <Tab label="Analytics" disabled={!selectedRule || hasUnsavedChanges} />}
      </Tabs>

      {selectedTab === 0 && <RuleOverviewTab />}
      {selectedTab === 1 && <RuleConfigurationTab />}
      {selectedTab === 2 && <RuleExecutionsTab />}
      {selectedTab === 3 && <RuleAnalyticsTab />}
    </Box>
  );
}
