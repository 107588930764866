import Stack from '@mui/material/Stack';
import React, { useContext, useEffect, useState } from 'react';
import { MessageAccordion } from '../MessageAccordion/MessageAccordion';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { SMS_ONLY, EMAIL_ONLY, EMAIL_AND_SMS, EMAIL_OR_SMS, SMS_OR_EMAIL } from '../../../../domain/ScheduledMessaging';
import { MessagingCodeSelect } from '../MessagingCodeSelect/MessagingCodeSelect';
import { MessageRecordingOptions } from '../MessageRecordingOptions/MessageRecordingOptions';
import { SendPreferenceOptions } from '../SendPreferenceOptions/SendPreferenceOptions';
import RulesContext from '../../../../context/RulesContext/RulesContext';
import Button from '@mui/material/Button';
import { MaxPatientsSlider } from '../MaxPatientsSlider/MaxPatientsSlider';
import { enqueueErrorNotification, enqueueSuccessNotification } from '../../../../redux/reducers/notificationsReducer';
import { useDispatch } from 'react-redux';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../../shared/ConfirmationDialog/ConfirmationDialog';
import { useDialog } from '../../../../hooks/useDialog/useDialog';
import { AppointmentBooking } from '../AppointmentBooking/AppointmentBooking';
import { Schedule } from '../Schedule/Schedule';
import { MinDaysBetweenMessagingRoundsSpinner } from '../MinDaysBetweenMessagingRoundsSpinner/MinDaysBetweenMessagingRoundsSpinner';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import { MaxMessagingRoundsSpinner } from '../MaxMessagingRoundsSpinner/MaxMessagingRoundsSpinner';
import StaffNoteEditor from '../StaffNoteEditor';
import PatientNeedsSummarySwitch from '../PatientNeedsSummarySwitch';
import { DoubleBookingConfiguration } from '../DoubleBookingConfiguration/DoubleBookingConfiguration';
import ConfigContext from '../../../../context/ConfigContext';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';

export function RuleConfigurationTab() {
  const { getRequiredSelectedRule, updateRuleConfig, toggleUnsavedChanges } = useContext(RulesContext);

  const {
    organisation: { ehr },
  } = useCurrentOrganisationDetails();

  const doubleBookingConfigurationEnabled =
    ehr && useContext(ConfigContext).features?.doubleBookingConfiguration?.[ehr] === true;

  const dispatch = useDispatch();

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  const selectedRule = getRequiredSelectedRule();

  const { preference } = selectedRule.config;
  const emailAndOrSms = new Set([EMAIL_AND_SMS, EMAIL_OR_SMS, SMS_OR_EMAIL]);

  const { patientSelectionCriteria, appointments } = selectedRule.config;
  const { doubleBookingPrevention } = patientSelectionCriteria;

  const form = useForm<FieldValues>({
    defaultValues: {
      subject: selectedRule.config.emailTemplate?.subject ?? '',
      content: selectedRule.config.emailTemplate?.content ?? '',
      message: selectedRule.config.smsTemplate?.message ?? '',
      sendEmails: preference && preference !== SMS_ONLY,
      sendTexts: preference && preference !== EMAIL_ONLY,
      smsAndEmailPreference: preference ? (emailAndOrSms.has(preference) ? preference : EMAIL_AND_SMS) : undefined,
      messageRecordingChoice: selectedRule.config.messageRecordingChoice,
      extraCodes: selectedRule.config.extraCodes,
      appointmentsAddInvitationLink: appointments.addInvitationLink,
      appointmentsBookableWithinDays: appointments.selfBooking?.bookableWithinDays ?? 7,
      appointmentsSlotTypes: appointments.selfBooking?.slotTypes,
      appointmentsDescription: appointments.description,
      maxPatients: [patientSelectionCriteria.maxDefinedByPractice, patientSelectionCriteria.maxAllowedByAbtrace],
      minDaysBetweenMessagingRounds: patientSelectionCriteria.minDaysBetweenMessagingRounds,
      maxMessagingRounds: patientSelectionCriteria.maxMessagingRounds,
      doubleBookingPreventionEnabled: doubleBookingPrevention?.enabled ?? false,
      doubleBookingPreventionEmbargoWindow: doubleBookingPrevention?.embargoWindow
        ? [doubleBookingPrevention.embargoWindow.fromDaysAgo, doubleBookingPrevention.embargoWindow.toDaysAhead]
        : [7, 7],
      doubleBookingPreventionSlotTypes: doubleBookingPrevention?.slotTypes ?? [],
      doubleBookingPreventionGpIds: doubleBookingPrevention?.gpIds ?? [],
      scheduleDaysOfWeek: selectedRule.config.schedule.daysOfWeek,
      scheduleHour: selectedRule.config.schedule.hour,
      staffNote: selectedRule.config.staffNote ?? '',
      includePatientNeedsSummary: selectedRule.config.patientNeedsSummary ?? false,
    },
  });

  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    toggleUnsavedChanges(form.formState.isDirty);
  }, [form.formState.isDirty]);

  async function doSave(
    {
      subject,
      content,
      message,
      sendEmails,
      sendTexts,
      smsAndEmailPreference,
      messageRecordingChoice,
      extraCodes,
      appointmentsAddInvitationLink,
      appointmentsBookableWithinDays,
      appointmentsSlotTypes,
      appointmentsDescription,
      maxPatients,
      minDaysBetweenMessagingRounds,
      maxMessagingRounds,
      doubleBookingPreventionEnabled,
      doubleBookingPreventionEmbargoWindow,
      doubleBookingPreventionSlotTypes,
      doubleBookingPreventionGpIds,
      scheduleDaysOfWeek,
      scheduleHour,
      staffNote,
      includePatientNeedsSummary,
    }: FieldValues,
    enabled: boolean = selectedRule.config.enabled,
  ) {
    setUpdating(true);

    try {
      await updateRuleConfig(selectedRule.id, {
        enabled,
        patientSelectionCriteria: {
          maxDefinedByPractice: Math.min(...maxPatients),
          maxAllowedByAbtrace: maxPatients[1],
          minDaysBetweenMessagingRounds: parseInt(minDaysBetweenMessagingRounds),
          maxMessagingRounds: parseInt(maxMessagingRounds),
          doubleBookingPrevention: doubleBookingConfigurationEnabled
            ? {
                enabled: doubleBookingPreventionEnabled,
                embargoWindow: {
                  fromDaysAgo: parseInt(doubleBookingPreventionEmbargoWindow[0]),
                  toDaysAhead: parseInt(doubleBookingPreventionEmbargoWindow[1]),
                },
                slotTypes: doubleBookingPreventionSlotTypes,
                gpIds: doubleBookingPreventionGpIds,
              }
            : undefined,
        },
        emailTemplate: {
          subject,
          content,
        },
        smsTemplate: {
          message,
        },
        preference:
          !sendEmails && !sendTexts
            ? undefined
            : sendEmails && !sendTexts
              ? EMAIL_ONLY
              : !sendEmails && sendTexts
                ? SMS_ONLY
                : smsAndEmailPreference,
        messageRecordingChoice,
        extraCodes,
        appointments: {
          addInvitationLink: appointmentsAddInvitationLink,
          description: appointmentsDescription,
          selfBooking:
            appointmentsAddInvitationLink && !!appointmentsBookableWithinDays && !!appointmentsSlotTypes
              ? {
                  bookableWithinDays: parseInt(appointmentsBookableWithinDays),
                  slotTypes: appointmentsSlotTypes,
                }
              : undefined,
        },
        schedule: {
          daysOfWeek: scheduleDaysOfWeek,
          hour: scheduleHour,
        },
        staffNote: staffNote || null,
        patientNeedsSummary: includePatientNeedsSummary,
      });

      form.reset({
        subject,
        content,
        message,
        sendEmails,
        sendTexts,
        smsAndEmailPreference,
        messageRecordingChoice,
        extraCodes,
        appointmentsAddInvitationLink,
        appointmentsBookableWithinDays,
        appointmentsSlotTypes,
        appointmentsDescription,
        maxPatients,
        minDaysBetweenMessagingRounds,
        maxMessagingRounds,
        doubleBookingPreventionEnabled,
        doubleBookingPreventionEmbargoWindow,
        doubleBookingPreventionSlotTypes,
        doubleBookingPreventionGpIds,
        scheduleDaysOfWeek,
        scheduleHour,
        staffNote,
        includePatientNeedsSummary,
      });

      dispatch(enqueueSuccessNotification('Changes applied'));
    } catch (error) {
      dispatch(enqueueErrorNotification('Unable to save changes', error));
    } finally {
      setUpdating(false);
    }
  }

  function onSave(formValues: FieldValues) {
    const enabled = selectedRule.config.enabled;
    const { maxPatients, sendEmails, sendTexts } = formValues;
    const effectiveMaxPatients = Math.min(...maxPatients);
    if (effectiveMaxPatients === 0 && enabled) {
      return open({
        title: 'Save changes',
        text: 'The maximum number of patients to contact is set to 0. This will effectively disable the rule. Are you sure you want to continue?',
      }).then(async () => {
        return doSave(formValues, false);
      });
    } else if (!sendEmails && !sendTexts && enabled) {
      return open({
        title: 'Save changes',
        text: 'You have unchecked both E-Mails and SMS messages. This will effectively disable the rule. Are you sure you want to continue?',
      }).then(async () => {
        return doSave(formValues, false);
      });
    } else {
      return doSave(formValues);
    }
  }

  const sendEmails = form.getValues('sendEmails');
  const sendTexts = form.getValues('sendTexts');

  return (
    <FormProvider {...form}>
      <Stack position="relative" gap={2}>
        <Stack gap={2}>
          <MessageAccordion />
          <SendPreferenceOptions visible={sendEmails && sendTexts} />
          <AppointmentBooking />
          <MessageRecordingOptions />
          <PatientNeedsSummarySwitch />
          <MessagingCodeSelect />
          <StaffNoteEditor />
          <MaxPatientsSlider />

          <FormControl component={Box}>
            <FormLabel>Messaging frequency</FormLabel>
            <Stack mt={2} ml={0} gap={1}>
              <Schedule />
              <MinDaysBetweenMessagingRoundsSpinner />
              <MaxMessagingRoundsSpinner />
            </Stack>
          </FormControl>

          {doubleBookingConfigurationEnabled ? (
            <FormControl component={Box}>
              <FormLabel>Double-booking</FormLabel>
              <Stack mt={2} ml={0} gap={1}>
                <DoubleBookingConfiguration />
              </Stack>
            </FormControl>
          ) : (
            <></>
          )}
        </Stack>

        <Stack
          position="sticky"
          bottom={0}
          zIndex={3}
          py={2}
          direction="row"
          spacing={2}
          justifyContent="end"
          borderTop="1px solid rgba(0,0,0,0.14)"
          sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
        >
          <Button variant="outlined" onClick={() => form.reset()} disabled={!form.formState.isDirty || updating}>
            Reset
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={form.handleSubmit(onSave)}
            loading={updating}
            disabled={updating || !form.formState.isDirty}
          >
            Save
          </Button>
        </Stack>
      </Stack>

      <ConfirmationDialog connector={connector} />
    </FormProvider>
  );
}
