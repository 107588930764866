import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import React, { useContext, useState } from 'react';
import UserContext from '../../../context/UserContext';
import { signOut } from 'aws-amplify/auth';

export const UserMenu = () => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const context = useContext(UserContext);

  const userInitials = () => {
    const { firstName, lastName } = context.user!;
    return firstName.charAt(0) + lastName.charAt(0);
  };

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchor === (event.target as HTMLElement)) {
      return;
    }

    setAnchor(null);
  };

  const open = Boolean(anchor);

  return (
    <>
      <Button onClick={handleToggle}>
        <Avatar>{context.user ? userInitials() : ''}</Avatar>
      </Button>
      <Popper open={open} anchorEl={anchor} transition sx={{ zIndex: (theme) => theme.zIndex.appBar + 1 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}>
                  <MenuItem>
                    <Typography>Logged in as: {context?.user?.email ?? ''}</Typography>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => signOut()}>Log Out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
