import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import PasswordField from '../../../shared/PasswordField/PasswordField';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import TextField from '../../../shared/TextField/TextField';
import { URLS } from '../../../../util/urls';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import { confirmResetPassword } from 'aws-amplify/auth';

interface Props {
  email: string;
  onPasswordChanged: () => void;
}

export default function PasswordReset({ email, onPasswordChanged }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const updateErrorMessage = (errorMessage: string) => {
    dispatch(enqueueErrorNotification(errorMessage));
  };

  const requestPasswordReset = ({ code, newPassword, newPasswordConfirmation }: FieldValues) => {
    if (newPassword !== newPasswordConfirmation) {
      updateErrorMessage('The passwords do not match');
      reset({
        code,
        newPassword: '',
        newPasswordConfirmation: '',
      });
    } else {
      setSubmitting(true);
      confirmResetPassword({ username: email, confirmationCode: code, newPassword: newPassword })
        .then(onPasswordChanged)
        .catch((error) => {
          updateErrorMessage(`Unable to change password: ${error.message}`);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(requestPasswordReset)}>
      <FormControl sx={{ rowGap: '24px', width: '290px' }}>
        <TextField
          id="code"
          name="code"
          control={control}
          error={errors.code as FieldError}
          rules={{
            required: 'Recovery code is required',
          }}
          label="Recovery code"
          autoFocus={true}
          required
        />

        <PasswordField
          id="newPassword"
          name="newPassword"
          control={control}
          error={errors.newPassword as FieldError}
          label="New password"
          required
          rules={{ required: 'New password is required' }}
        />

        <PasswordField
          id="newPasswordConfirmation"
          name="newPasswordConfirmation"
          control={control}
          error={errors.newPasswordConfirmation as FieldError}
          label="Confirm new password"
          required
          rules={{ required: 'Password confirmation is required' }}
        />

        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Link component={RouterLink} to={URLS.LOGIN}>
            <Button color="primary">Back</Button>
          </Link>

          <Button
            type="submit"
            data-testid="update-password"
            variant="contained"
            color="primary"
            loading={submitting}
            onClick={handleSubmit(requestPasswordReset)}
          >
            Set new password
          </Button>
        </Stack>
      </FormControl>
    </form>
  );
}
