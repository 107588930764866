import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';
import { InternalSnack, SnackbarProvider, SnackbarProviderProps } from 'notistack';
import React, { ReactNode } from 'react';

const StyledSnackbarProvider = styled(SnackbarProvider)<SnackbarProviderProps>({
  '&.notistack-MuiContent-info': {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    flexWrap: 'nowrap',
  },
  '&.notistack-MuiContent-success': {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    flexWrap: 'nowrap',
  },
  '&.notistack-MuiContent-warn': {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    flexWrap: 'nowrap',
  },
  '&.notistack-MuiContent-error': {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    flexWrap: 'nowrap',
  },
});

interface SnackbarState {
  snacks: InternalSnack[];
}

export function NotificationsProvider({ children }: { children: ReactNode }) {
  const notistackRef = React.createRef<SnackbarProvider>();

  function onClickAway() {
    if (notistackRef.current) {
      (notistackRef.current.state as SnackbarState).snacks
        .filter((snack) => !snack.persist)
        .forEach((snack) => {
          notistackRef.current!.closeSnackbar(snack.id);
        });
    }
  }

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <StyledSnackbarProvider preventDuplicate maxSnack={3} ref={notistackRef} TransitionComponent={Fade}>
        {children}
      </StyledSnackbarProvider>
    </ClickAwayListener>
  );
}
