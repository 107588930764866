import { isAxiosError } from 'axios';
import { errorTolog, newLogger } from '../../logging/logger';
import { TIMEOUT } from '../constants';
import { ApiError } from '../../services/ApiError';

export function handleApiError(error: unknown): never {
  const logger = newLogger('Api');

  if (isAxiosError(error) && error.config) {
    const { method, url, headers } = error.config;
    const traceId = headers?.['X-Trace-Id']?.toString();

    logger.error(`${method?.toUpperCase()} ${url}: ${error.response?.status ?? error.message}`, {
      error: errorTolog(error),
      traceId,
    });

    let errorDescription = (error.response?.data as { error: string })?.error ?? error.message;
    let code: string | undefined = undefined;
    if (error.code === 'ECONNABORTED') {
      errorDescription = 'Please confirm that you are on the correct network and try again.';
      code = TIMEOUT;
    }

    throw new ApiError(errorDescription, { cause: error, code, traceId });
  } else if (error instanceof Error) {
    logger.error(error.message, {
      error: errorTolog(error),
    });

    throw new ApiError(error.message, { cause: error });
  } else {
    logger.error(String(error), {
      error: errorTolog(error),
    });

    throw new ApiError(String(error), {
      cause: {
        message: String(error),
        name: 'n/a',
      },
    });
  }
}
