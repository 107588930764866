import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import UserContext from '../../../context/UserContext';
import { OrganisationMember, Permission } from '../../../domain/Organisation';
import { User, isOrgAdmin } from '../../../domain/User';

interface Props {
  organisation: string;
  users: OrganisationMember[];
  updateUserPermissions: (
    user: OrganisationMember,
    permission: Permission,
  ) => (ev: React.ChangeEvent<HTMLInputElement>) => void;
  deleteUser: (user: OrganisationMember) => void;
}

function AdminRow({
  member,
  userPermissions,
  updateMemberPermissions,
  deleteMember,
}: {
  member: OrganisationMember;
  userPermissions: UserPermissions;
  updateMemberPermissions: Props['updateUserPermissions'];
  deleteMember: Props['deleteUser'];
}) {
  const {
    canEditAdminPermission,
    canEditOptimisationPermission,
    canEditBetaTesterPermission,
    canEditAnalyticsPermission,
    canDeleteMember,
  } = userPermissions.getMemberPermissions(member);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {member.email}
      </TableCell>
      <TableCell>
        <Stack direction="row" gap={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={member.permissions.includes('admin')}
                  onChange={updateMemberPermissions(member, 'admin')}
                />
              }
              label="Admin"
              disabled={!canEditAdminPermission}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={member.permissions.includes('optimisation')}
                  onChange={updateMemberPermissions(member, 'optimisation')}
                  disabled={!canEditOptimisationPermission}
                />
              }
              label="Optimisation"
            />
          </FormGroup>
          {canEditBetaTesterPermission && (
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={member.permissions.includes('betaTester')}
                    onChange={updateMemberPermissions(member, 'betaTester')}
                  />
                }
                label="Beta tester"
              />
            </FormGroup>
          )}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={member.permissions.includes('analytics')}
                  onChange={updateMemberPermissions(member, 'analytics')}
                  disabled={!canEditAnalyticsPermission}
                />
              }
              label="Analytics"
            />
          </FormGroup>
        </Stack>
      </TableCell>
      <TableCell>
        <Button onClick={() => deleteMember(member)} disabled={!canDeleteMember}>
          Delete
        </Button>
      </TableCell>
    </TableRow>
  );
}

function UserRow({ user }: { user: OrganisationMember }) {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {user.email}
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
}

function getUserPermissions(user: User, organisationId: string) {
  const { superUser, supportUser } = user;
  const orgAdmin = isOrgAdmin(user, organisationId);

  return {
    canSeeAdminRow: superUser || supportUser || orgAdmin,
    getMemberPermissions: (member: OrganisationMember) => ({
      canEditAdminPermission: superUser || supportUser || (orgAdmin && member.email !== user.email),
      canEditOptimisationPermission: superUser || supportUser || orgAdmin,
      canEditBetaTesterPermission: superUser || supportUser,
      canEditAnalyticsPermission: superUser || supportUser || orgAdmin,
      canDeleteMember: supportUser || superUser || (orgAdmin && member.email !== user.email),
    }),
    superUser,
    supportUser,
    orgAdmin,
  };
}

type UserPermissions = ReturnType<typeof getUserPermissions>;

export default function OrganisationUsersTable(props: Props) {
  const { organisation, users } = props;
  const context = useContext(UserContext);
  const currentUser = context!.user!;
  const userPermissions = getUserPermissions(currentUser, organisation);
  const { canSeeAdminRow } = userPermissions;

  if (users.length === 0) {
    return <Typography>This organisation doesn&apos;t have any {canSeeAdminRow ? 'users' : 'admins'} yet</Typography>;
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>User e-mail</TableCell>
            <TableCell>{canSeeAdminRow && 'Permissions'}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users
            .sort((a, b) => a.email.localeCompare(b.email))
            .map((member) => {
              return canSeeAdminRow ? (
                <AdminRow
                  key={member.email}
                  member={member}
                  updateMemberPermissions={props.updateUserPermissions}
                  deleteMember={props.deleteUser}
                  userPermissions={userPermissions}
                />
              ) : (
                <UserRow key={member.email} user={member} />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
