import { ThemeProvider } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import useNotifications from '../hooks/useNotifications/useNotifications';
import { currentTheme } from '../util/theme';

interface Props {
  children: ReactNode;
}

export default function App({ children }: Props) {
  useNotifications();

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
}
