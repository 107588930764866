export const APPOINTMENT_BOOKING_LINK_MORE_INFO_URL = 'https://help.abtrace.co/booking-links';

export interface AppointmentsConfig {
  enabled: boolean;
  organisationEmail: {
    address: string | null;
    verified: boolean;
  } | null;
}

export interface SlotType {
  id: string;
  name: string;
}

export interface AppointmentSlotsConfig {
  holders: { id: string; guid?: string; name: string }[];
  sites: { id: string; name: string }[];
  slotTypes: SlotType[];
}
