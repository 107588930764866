import { AxiosInstance } from 'axios';
import { Application } from '../../domain/Application';
import { DownloadUrls } from '../../domain/DownloadUrls';
import { Invitation } from '../../domain/Invitation';
import { OrganisationMember } from '../../domain/Organisation';
import { WEBSITE_API_NAME } from '../../util/constants';
import { OrgConfig } from '../../pages/overview/helpers';
import { callApi, getApiClient, resetApiClient } from '../../util/api';

function client(): AxiosInstance {
  return getApiClient(WEBSITE_API_NAME);
}

export async function loadUserlessOrganisations(): Promise<{ organisations: string[] }> {
  return callApi(client().get, '/organisations?orgsWithoutUsers=true') as Promise<{ organisations: string[] }>;
}

export async function loadAllOrganisations(): Promise<{ organisations: string[] }> {
  return callApi(client().get, '/organisations') as Promise<{ organisations: string[] }>;
}

export async function loadUsers(organisation: string): Promise<OrganisationMember[]> {
  return callApi(client().get, `/organisations/${organisation}/users`) as Promise<OrganisationMember[]>;
}

export async function updateUser(
  userId: string,
  newPermissions: string[],
  organisation: string,
): Promise<OrganisationMember> {
  return callApi(client().post, `/organisations/${organisation}/users/${userId}`, {
    permissions: newPermissions,
  }) as Promise<OrganisationMember>;
}

export async function deleteUser(userId: string, organisation: string): Promise<unknown> {
  return callApi(client().delete, `/organisations/${organisation}/users/${userId}`);
}

export async function loadOrganisationInvitations(organisation: string): Promise<Invitation[]> {
  return callApi(client().get, `/organisations/${organisation}/invitations`) as Promise<Invitation[]>;
}

export async function invite(email: string, organisation: string): Promise<Invitation> {
  return callApi(client().post, `/organisations/${organisation}/invitations`, { email }) as Promise<Invitation>;
}

export async function deleteInvitation(email: string, organisation: string) {
  return callApi(client().delete, `/organisations/${organisation}/invitations/${email}`);
}

export async function loadUserInvitations(): Promise<Invitation[]> {
  return callApi(client().get, '/organisations/invitations') as Promise<Invitation[]>;
}

export async function acceptInvitation(organisation: string): Promise<OrganisationMember> {
  return callApi(client().patch, `/organisations/${organisation}/invitations`, {
    accept: true,
  }) as Promise<OrganisationMember>;
}

export async function declineInvitation(organisation: string): Promise<unknown> {
  return callApi(client().patch, `/organisations/${organisation}/invitations`, { accept: false });
}

export async function loadOrganisationApplications(organisation: string): Promise<Application[]> {
  return callApi(client().get, `/organisations/${organisation}/applications`) as Promise<Application[]>;
}

export async function loadUserApplications(): Promise<Application[]> {
  return callApi(client().get, '/organisations/my/applications') as Promise<Application[]>;
}

export async function apply(organisation: string): Promise<Application> {
  return callApi(client().post, `/organisations/${organisation}/applications`, {}) as Promise<Application>;
}

export async function withdrawApplication(application: Application): Promise<Application> {
  return callApi(client().patch, `/organisations/${application.organisation}/applications/${application.id}`, {
    status: 'WITHDRAWN',
  }) as Promise<Application>;
}

export async function approveApplication(application: Application): Promise<Application> {
  return callApi(client().patch, `/organisations/${application.organisation}/applications/${application.id}`, {
    status: 'APPROVED',
  }) as Promise<Application>;
}

export async function denyApplication(application: Application): Promise<Application> {
  return callApi(client().patch, `/organisations/${application.organisation}/applications/${application.id}`, {
    status: 'DENIED',
  }) as Promise<Application>;
}

export async function getDownloadUrls(): Promise<DownloadUrls> {
  return callApi(client().get, '/latest', undefined, undefined, false, true) as Promise<DownloadUrls>;
}

export async function loadOrgConfigs(organisations: string[]): Promise<Record<string, OrgConfig>> {
  return (
    (await callApi(client().get, '/configs', undefined, {
      params: { organisations: organisations.join(',') },
      timeout: 40_000,
    })) as { configs: Record<string, OrgConfig> }
  ).configs;
}

export function resetClient() {
  resetApiClient(WEBSITE_API_NAME);
}
