import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import PasswordField from '../../../../components/shared/PasswordField/PasswordField';
import TextField from '../../../../components/shared/TextField/TextField';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { friendlierRegistrationError } from '../../../../util/errors/errors';
import NhsNetEmailField from '../../../shared/NhsNetEmailField/NhsNetEmailField';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import { PasswordRequirement } from './PasswordRequirement';
import { signUp } from 'aws-amplify/auth';
import Button from '@mui/material/Button';

interface Props {
  onRegister: () => void;
}

/*
 * - At least one digit
 * - At least one lowercase letter
 * - At least one uppercase letter
 * - At least one symbol of - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` + =
 * - At least 8 characters
 */
const STRONG_PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-^$*.[\]{}()?"!@#%&/\\,><':;|_~`+=])(?=.{8,}).*$/;

export default function RegistrationForm({ onRegister }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const updateErrorMessage = (errorMessage: string) => {
    dispatch(enqueueErrorNotification(errorMessage));
  };

  const register = ({ email, firstName, lastName, role, password, passwordConfirmation }: FieldValues) => {
    if (password !== passwordConfirmation) {
      updateErrorMessage('The passwords do not match');
      reset({
        email,
        firstName,
        lastName,
        role,
        password: '',
        passwordConfirmation: '',
      });

      return;
    }

    setSubmitting(true);
    signUp({
      username: email,
      password,
      options: {
        userAttributes: {
          email,
          given_name: firstName,
          family_name: lastName,
          'custom:role': role,
        },
      },
    })
      .then(onRegister)
      .catch((err) => {
        updateErrorMessage(`Unable to register: ${friendlierRegistrationError(err.message)}`);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <form onSubmit={handleSubmit(register)}>
      <FormControl sx={{ rowGap: '24px', width: '290px' }}>
        <NhsNetEmailField
          id="email"
          name={'email'}
          control={control}
          error={errors.email as FieldError}
          rules={{
            required: 'E-mail is required',
          }}
          label="E-mail"
          autoFocus={true}
          required
        />

        <Stack direction="row" spacing={2}>
          <TextField
            id="firstName"
            name={'firstName'}
            control={control}
            error={errors.firstName as FieldError}
            rules={{
              required: 'First name is required',
              invalid: 'Please enter a valid name',
            }}
            label="First name"
            required
          />

          <TextField
            id="lastName"
            name={'lastName'}
            control={control}
            error={errors.lastName as FieldError}
            rules={{
              required: 'Last name is required',
              invalid: 'Please enter a valid name',
            }}
            label="Last name"
            required
          />
        </Stack>

        <TextField
          id="role"
          name={'role'}
          control={control}
          error={errors.role as FieldError}
          rules={{
            required: 'Role is required',
          }}
          label="Role"
          required
        />
        <PasswordRequirement isError={!!errors.password && errors.password.type === 'pattern'} />
        <PasswordField
          id="password"
          name={'password'}
          control={control}
          error={errors.password as FieldError}
          label="Password"
          required
          rules={{
            required: 'Password is required',
            pattern: {
              value: STRONG_PASSWORD_REGEX,
              message: 'Please choose a stronger password',
            },
          }}
          slotProps={{
            formHelperText: {
              component: 'div',
            },
          }}
        />

        <PasswordField
          id="passwordConfirmation"
          name="passwordConfirmation"
          control={control}
          error={errors.passwordConfirmation as FieldError}
          label="Confirm password"
          required
          rules={{ required: 'Password confirmation is required' }}
        />

        <Button
          type="submit"
          data-testid="register"
          variant="contained"
          loading={submitting}
          onClick={handleSubmit(register)}
        >
          Register
        </Button>
      </FormControl>
    </form>
  );
}
