import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { Box, styled } from '@mui/system';
import { LoadingSlider } from '../../../shared/LoadingSlider/LoadingSlider';

const StyledFormControlLabel = styled(FormControlLabel)({
  '& .slider-label': {
    minWidth: 250,
  },
});

interface Props {
  enabled: boolean;
  limit?: number | null;
  saveSmsLimit: (newLimit: number) => Promise<void>;
}

export function SmsAllowanceSlider({ enabled, limit, saveSmsLimit }: Props) {
  const marks = [
    {
      value: 0,
      label: 'None',
    },
    {
      value: 1000,
    },
    {
      value: 2000,
    },
    {
      value: 3000,
    },
    {
      value: 4000,
    },
    {
      value: 5000,
      label: Number(5000).toLocaleString(),
    },
    {
      value: 6000,
    },
    {
      value: 7000,
    },
    {
      value: 8000,
    },
    {
      value: 9000,
    },
    {
      value: 10000,
      label: Number(10000).toLocaleString(),
    },
    {
      value: 11000,
    },
    {
      value: 12000,
    },
    {
      value: 13000,
    },
    {
      value: 14000,
    },
    {
      value: 15000,
      label: Number(15000).toLocaleString(),
    },
    {
      value: 16000,
    },
    {
      value: 17000,
    },
    {
      value: 18000,
    },
    {
      value: 19000,
    },
    {
      value: 20000,
      label: Number(20000).toLocaleString(),
    },
  ];

  const [value, setValue] = useState(limit ?? 0);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setValue(limit ?? 0);
  }, [limit]);

  async function onSaveSmsLimit(newLimit: number) {
    setSaving(true);

    try {
      await saveSmsLimit(newLimit);
    } catch {
      // The error is handled in the parent component
    } finally {
      setSaving(false);
    }
  }

  return (
    <Box maxWidth={700}>
      <FormControl fullWidth>
        <StyledFormControlLabel
          control={
            <LoadingSlider
              aria-label="Monthly SMS allowance"
              marks={marks}
              min={0}
              max={20000}
              step={null}
              value={value ?? 0}
              onChange={(_ev, newValue) => setValue(newValue as number)}
              onChangeCommitted={(_ev, newValue) => onSaveSmsLimit(newValue as number)}
              loading={saving}
              disabled={!enabled || saving}
            />
          }
          label="Monthly SMS allowance:"
          labelPlacement="start"
          classes={{
            label: 'slider-label',
          }}
        />
      </FormControl>
    </Box>
  );
}
