import { Link as RouterLink, useNavigate } from 'react-router';
import { URLS } from '../../util/urls';
import { useDispatch } from 'react-redux';
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import RegistrationForm from '../../components/auth/registration/RegistrationForm/RegistrationForm';
import { enqueueInfoNotification } from '../../redux/reducers/notificationsReducer';
import Grid2 from '@mui/material/Grid2';

export default function RegistrationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onRegister = () => {
    dispatch(enqueueInfoNotification('Account created successfully. Please check your inbox.'));
    navigate(URLS.LOGIN);
  };

  return (
    <Grid2 container spacing={0} justifyContent="center" alignContent="center" direction="column">
      <Grid2>
        <Box mt={14} mb={1}>
          <Typography variant="h5" gutterBottom>
            Create account
          </Typography>

          <RegistrationForm onRegister={onRegister} />
        </Box>

        <Divider />

        <Box mt={1}>
          <Typography variant="body1" textAlign="center">
            Already have an account?{' '}
            <Link component={RouterLink} to={URLS.LOGIN}>
              Sign-in
            </Link>{' '}
          </Typography>
        </Box>
      </Grid2>
    </Grid2>
  );
}
