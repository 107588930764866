import React from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import RequestCodeForm from '../../components/auth/resetpassword/RequestCodeForm/RequestCodeForm';
import ResetPasswordForm from '../../components/auth/resetpassword/ResetPasswordForm/ResetPasswordForm';
import { useDispatch } from 'react-redux';
import { URLS } from '../../util/urls';
import { enqueueInfoNotification } from '../../redux/reducers/notificationsReducer';
import Grid2 from '@mui/material/Grid2';

export default function ResetPasswordPage() {
  const [email, setEmail] = React.useState('');
  const [codeSent, setCodeSent] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCodeRequested = (requestEmail: string) => {
    setEmail(requestEmail);
    setCodeSent(true);
    dispatch(
      enqueueInfoNotification(
        `An account recovery code has been sent to ${requestEmail}. Please enter it above, together with a new password.`,
      ),
    );
  };

  const onPasswordChanged = () => {
    dispatch(
      enqueueInfoNotification(
        'Your new password has been successfully set - you are being redirected back to the login page.',
      ),
    );

    setTimeout(() => {
      navigate(URLS.LOGIN);
    }, 2000);
  };

  return (
    <Grid2 container spacing={0} justifyContent="center" alignContent="center" direction="column">
      <Grid2 mt={28}>
        <Typography variant="h5" gutterBottom>
          Password reset
        </Typography>

        {codeSent ? (
          <ResetPasswordForm email={email} onPasswordChanged={onPasswordChanged} />
        ) : (
          <RequestCodeForm onCodeRequested={onCodeRequested} />
        )}
      </Grid2>
    </Grid2>
  );
}
