import React, { useContext } from 'react';
import UserContext from '../../context/UserContext';
import NavBar from '../../components/navigation/NavBar/NavBar';
import { Box } from '@mui/system';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import UserInvitationsPanel from '../../components/invitations/UserInvitationsPanel/UserInvitationsPanel';
import PendingUserApplicationsPanel from '../../components/applications/PendingUserApplicationsPanel/PendingUserApplicationsPanel';
import DownloadsPanel from '../../components/downloads/DownloadsPanel';
import UserOrganisations from '../../components/organisations/UserOrganisations/UserOrganisations';
import OtherOrganisations from '../../components/organisations/OtherOrganisations/OtherOrganisations';
import UserlessOrganisations from '../../components/organisations/UserlessOrganisations';
import Grid2 from '@mui/material/Grid2';

export default function HomePage() {
  const context = useContext(UserContext);
  const user = context.user;
  const organisations = user?.organisations ?? [];
  const canManageOrgs = user?.superUser || user?.supportUser;

  return (
    <>
      <NavBar />

      <Grid2 component="main" overflow="auto" container>
        <Grid2 size={8}>
          {canManageOrgs && (
            <Box m={2}>
              <Typography gutterBottom variant="h4" component="div">
                Onboarding organisations
              </Typography>
              <Divider />
              <Box mt={2}>
                <UserlessOrganisations />
              </Box>
            </Box>
          )}

          <Box m={2}>
            <Typography gutterBottom variant="h4" component="div">
              Organisations
            </Typography>
            <Divider />
            <Box mt={2}>
              <UserOrganisations organisations={organisations} />
            </Box>
          </Box>

          {canManageOrgs && (
            <Box m={2}>
              <Typography gutterBottom variant="h4" component="div">
                Other Organisations
              </Typography>
              <Divider />
              <Box mt={2}>
                <OtherOrganisations exclude={organisations.map((org) => org.name)} />
              </Box>
            </Box>
          )}
        </Grid2>
        <Grid2 size={4}>
          <UserInvitationsPanel />
          <PendingUserApplicationsPanel />
          <DownloadsPanel />
        </Grid2>
      </Grid2>
    </>
  );
}
