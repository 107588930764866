import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../redux/reducers/notificationsReducer';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_STRING_ORGANISATION_DETAILS } from '../../util/constants';
import { SCHEDULED_MESSAGING_QUERY_KEY } from '../../util/queries';
import { OrgDetails } from './helpers';
import { updateScheduledMessagingOrganisationConfig } from '../../apis/scheduledMessaging/ScheduledMessagingApi';

interface Props {
  organisation: string;
  value: boolean;
}

export const AutomatedMessagingEnabledCheckbox: React.FC<Props> = ({ organisation, value }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = event.target.checked;
    try {
      await updateScheduledMessagingOrganisationConfig(organisation, updatedValue);

      queryClient.invalidateQueries({ queryKey: [SCHEDULED_MESSAGING_QUERY_KEY, organisation] });
      queryClient.setQueryData([QUERY_STRING_ORGANISATION_DETAILS], (previousConfig: OrgDetails[]) => {
        return previousConfig.map((org: OrgDetails) =>
          org.id === organisation ? { ...org, automatedMessagingEnabled: updatedValue } : org,
        );
      });
      dispatch(enqueueInfoNotification('Organisation configuration updated'));
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    }
  };

  return <Checkbox checked={value} onChange={onChange} />;
};
