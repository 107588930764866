import * as React from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid2 from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { Ref, useContext, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router';
import ChangePasswordForm from '../../components/auth/login/ChangePasswordForm/ChangePasswordForm';
import LoginForm from '../../components/auth/login/LoginForm/LoginForm';
import UserContext from '../../context/UserContext';
import { User } from '../../domain/User';
import { URLS } from '../../util/urls';

const Alert = React.forwardRef((props: AlertProps, ref: Ref<HTMLDivElement>) => {
  return (
    <MuiAlert elevation={6} ref={ref} variant="filled" {...props}>
      {props.children}
    </MuiAlert>
  );
});

Alert.displayName = 'Alert';

export default function LoginPage() {
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);

  const [requireChangePassword, setRequireChangePassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { updateCurrentUser } = useContext(UserContext);

  function handleCloseSnackbar() {
    setSnackbarOpen(false);
  }

  function onLogin(user: User) {
    updateCurrentUser(user);
    navigate(location);
  }

  function onPasswordChanged(user: User) {
    setSnackbarOpen(true);
    setRequireChangePassword(false);
    onLogin(user);
  }

  function onPasswordChangeRequest(userEmail: string, userPassword: string) {
    setEmail(userEmail);
    setPassword(userPassword);
    setRequireChangePassword(true);
  }

  return (
    <Grid2 container spacing={0} justifyContent="center" alignContent="center" direction="column">
      <Grid2>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success">
            The password has been successfully updated
          </Alert>
        </Snackbar>

        <Box mt={28} mb={1}>
          {requireChangePassword ? (
            <ChangePasswordForm
              email={email!}
              password={password!}
              onPasswordChanged={(user) => onPasswordChanged(user)}
            />
          ) : (
            <LoginForm
              onLogin={(user) => onLogin(user)}
              onPasswordChangeRequest={(...args) => onPasswordChangeRequest(...args)}
            />
          )}
        </Box>

        <Divider />

        <Box mt={1}>
          <Typography variant="body1" textAlign="center">
            Don&apos;t have an account yet?{' '}
            <Link component={RouterLink} to={URLS.REGISTER}>
              Register
            </Link>{' '}
          </Typography>
        </Box>
      </Grid2>
    </Grid2>
  );
}
