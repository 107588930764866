import { signIn, getCurrentUser , fetchAuthSession } from 'aws-amplify/auth';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useContext, useState } from 'react';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router';
import ConfigContext from '../../../../context/ConfigContext';
import { parseIdToken, User } from '../../../../domain/User';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import EmailField from '../../../shared/EmailField/EmailField';
import PasswordField from '../../../shared/PasswordField/PasswordField';
import Button from '@mui/material/Button';

interface Props {
  onLogin: (user: User) => void;
  onPasswordChangeRequest: (email: string, password: string) => void;
}

const LoginForm = ({ onLogin, onPasswordChangeRequest }: Props) => {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const config = useContext(ConfigContext);

  const updateErrorMessage = (errorMessage: string) => {
    dispatch(enqueueErrorNotification(errorMessage));
  };

  async function login({ email, password }: FieldValues) {
    setSubmitting(true);

    try {
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password,
        options: { service: config.datadog.service },
      });

      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        onPasswordChangeRequest(email, password);
      } else if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        throw new Error('User is not confirmed');
      } else if (isSignedIn) {
        const currentUser = await getCurrentUser();
        const session = await fetchAuthSession();

        const idToken = session.tokens?.idToken;

        if (!idToken) {
          throw new Error('Invalid ID Token');
        }

        const jwtPayload = idToken.payload;

        const user = {
          email: jwtPayload.email as string,
          firstName: (jwtPayload.given_name as string) ?? '',
          lastName: (jwtPayload.family_name as string) ?? '',
          role: (jwtPayload['custom:role'] as string) ?? '',
          organisations: parseIdToken(idToken.toString()!),
          userId: currentUser.username,
          superUser: jwtPayload['custom:super_user'] === 'true',
          supportUser: jwtPayload['custom:support_user'] === 'true',
        };

        onLogin(user);
      }
    } catch (error) {
      updateErrorMessage(`Unable to login: ${error instanceof Error ? error.message : new String(error)}`);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Sign In
      </Typography>

      <form onSubmit={handleSubmit(login)}>
        <FormControl sx={{ rowGap: '24px', width: '290px' }}>
          <EmailField
            id="email"
            name={'email'}
            control={control}
            error={errors.email as FieldError}
            rules={{
              required: 'E-mail is required',
              invalid: 'Please enter a valid e-mail',
            }}
            label="E-mail"
            required
            autoFocus={true}
          />

          <PasswordField
            id="password"
            name={'password'}
            control={control}
            error={errors.password as FieldError}
            label="Password"
            required
            rules={{ required: 'Password is required' }}
          />

          <Box sx={{ position: 'relative' }}>
            <Button
              type="submit"
              data-testid="sign-in"
              variant="contained"
              loading={submitting}
              onClick={handleSubmit(login)}
              sx={{ width: '100%' }}
            >
              Sign In
            </Button>
          </Box>

          <Typography variant="body1">
            Forgot password? Click{' '}
            <Link component={RouterLink} to="/passwordreset">
              here
            </Link>{' '}
            to reset it.
          </Typography>
        </FormControl>
      </form>
    </>
  );
};

export default connect()(LoginForm);
