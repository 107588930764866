import TextField from '@mui/material/TextField';
import Box from '@mui/system/Box';
import React, { useState } from 'react';
import { NOT_CURRENTLY_SET } from '../../../util/constants';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { EmailDialog, EmailDialogConfig } from './EmailDialog';
import { resendVerificationCode } from '../../../apis/appointments/AppointmentsApi';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../../redux/reducers/notificationsReducer';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import { useCurrentOrganisationDetails } from '../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';

interface Props {
  email: string | null;
  verified: boolean;
  onEmailVerify: (verificationCode: string) => Promise<void>;
  onEmailUpdate: (email: string | null) => Promise<void>;
}

export const EmailConfig: React.FC<Props> = ({ email, verified, onEmailUpdate, onEmailVerify }) => {
  const {
    organisation: { organisation },
  } = useCurrentOrganisationDetails();

  const [isResendingVerificationCode, setIsResendingVerificationCode] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verifying, setVerifying] = useState(false);

  const toBeVerified = !!email && !verified;

  const dispatch = useDispatch();

  const { open, connector } = useDialog<EmailDialogConfig, string | null>();

  const verifyEmailClickHandler = async () => {
    setVerifying(true);
    try {
      await onEmailVerify(verificationCode);
    } catch (error) {
      dispatch(enqueueErrorNotification('Unable to verify email', error));
    } finally {
      setVerifying(false);
    }
  };

  function openEmailDialog() {
    open({
      email,
    }).then(async (newEmail) => {
      try {
        await onEmailUpdate(newEmail);
      } catch (error) {
        dispatch(enqueueErrorNotification('Unable to update email address', error));
        throw error;
      }
    });
  }

  const resendClickHandler = async () => {
    setIsResendingVerificationCode(true);
    try {
      await resendVerificationCode(organisation);
      dispatch(enqueueInfoNotification('Verification code sent'));
    } catch (error) {
      dispatch(enqueueErrorNotification('Unable to send verification code', error));
    } finally {
      setIsResendingVerificationCode(false);
    }
  };

  return (
    <>
      <Box maxWidth={700}>
        <Alert icon={<InfoIcon />} severity="info">
          Appointment request forms will not be available to your patients unless an email address is set. The form is
          shown to patients when no appointment slots are available for self-booking.
        </Alert>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction="row" gap={2} alignItems="center" mt={1}>
          <Typography>Send appointment request forms to: </Typography>
          {email ? (
            <Stack component="span" direction="row" gap={1} alignItems="center">
              <MailOutlineIcon />
              <Typography>
                <Link href={`mailto:${email}`}>{email}</Link>
              </Typography>
            </Stack>
          ) : (
            <Typography color="text.secondary" sx={{ userSelect: 'none' }}>
              <em>{NOT_CURRENTLY_SET}</em>
            </Typography>
          )}
          {verified ? (
            <Chip label="Verified" color="success" icon={<CheckCircleIcon />} />
          ) : email ? (
            <Chip label="Unverified" color="error" icon={<ErrorIcon />} />
          ) : (
            <></>
          )}

          <Button onClick={openEmailDialog} variant="outlined">
            Change E-Mail...
          </Button>
        </Stack>
      </Box>
      {toBeVerified && (
        <Box pt={1} sx={{ width: '540px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TextField
            onChange={(e) => setVerificationCode(e.target.value)}
            sx={{ width: '300px' }}
            label="Verification Code"
            variant="outlined"
            value={verificationCode}
          />
          <Button
            loading={verifying}
            disabled={!verificationCode}
            onClick={verifyEmailClickHandler}
            variant="contained"
          >
            Verify
          </Button>
          <Button loading={isResendingVerificationCode} onClick={resendClickHandler} variant="outlined">
            Resend Code
          </Button>
        </Box>
      )}
      <EmailDialog connector={connector} />
    </>
  );
};
