import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridRowParams,
  GridRowId,
} from '@mui/x-data-grid-pro';
import React, { useCallback, useState } from 'react';
import { TRIGGER_RULE_DISABLED, TRIGGER_RULE_HIDDEN, Toolbar } from './Toolbar/Toolbar';
import { ExecutionStatusChip } from './ExecutionStatusChip/ExecutionStatusChip';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import { formatDuration } from '../../../../util/duration';
import { ExecutionDetails } from './ExecutionDetails/ExecutionDetails';

export interface Props {
  rows: GridRowsProp;
  loading?: boolean;
  triggerRule: (() => Promise<unknown>) | typeof TRIGGER_RULE_DISABLED | typeof TRIGGER_RULE_HIDDEN;
  refresh: () => Promise<unknown>;
}

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    triggerRule: (() => Promise<unknown>) | typeof TRIGGER_RULE_DISABLED | typeof TRIGGER_RULE_HIDDEN;
    refresh: () => Promise<unknown>;
  }
}

export const ExecutionsDataGrid: React.FC<Props> = ({ rows, loading, triggerRule, refresh }) => {
  const [openRowId, setOpenRowId] = useState<GridRowId | null>(null);

  const columns: GridColDef[] = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      sortable: false,
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 200,
      editable: false,
      resizable: false,
      valueFormatter: (value) => dayjs(value).format('ddd DD/MM/YYYY HH:mm'),
      renderCell: ({ formattedValue }) => (
        <Typography variant="body2" fontFamily="monospace" component="span">
          {formattedValue}
        </Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: false,
      resizable: false,
      renderCell: ({ value }) => <ExecutionStatusChip status={value} />,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      width: 150,
      editable: false,
      resizable: false,
      valueFormatter: (value) => (value ? formatDuration(value) : ''),
    },
  ];

  return (
    <Box height={'calc(100vh - 191px)'}>
      <DataGridPro
        sx={{
          '.MuiDataGrid-detailPanel': {
            borderBottom: '1px solid',
            borderColor: 'grey.300',
          },
          '.MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within':
            {
              outline: 'none',
            },

          '.MuiDataGrid-cell:hover, .MuiDataGrid-cell:hover *': {
            cursor: 'pointer',
          },
        }}
        rows={rows}
        columns={columns}
        pagination={true}
        pageSizeOptions={[25]}
        hideFooterSelectedRowCount={true}
        loading={loading}
        disableColumnMenu
        slots={{
          toolbar: Toolbar,
        }}
        slotProps={{
          toolbar: {
            triggerRule,
            refresh,
          },
        }}
        getDetailPanelContent={useCallback(({ row }) => {
          return <ExecutionDetails executionId={row.id} />;
        }, [])}
        getDetailPanelHeight={useCallback((row: GridRowParams) => (row.id === openRowId ? 'auto' : 0), [openRowId])}
        detailPanelExpandedRowIds={openRowId ? [openRowId] : []}
        onRowClick={({ row }) => setOpenRowId(openRowId === row.id ? null : row.id)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              [GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field]: false,
            },
          },
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 25,
            },
          },
        }}
      />
    </Box>
  );
};
