import React, { useMemo } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { AppointmentBookingCheckbox } from '../AppointmentBookingCheckbox/AppointmentBookingCheckbox';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Refresh from '@mui/icons-material/Refresh';
import { useAppointmentBookingConfig } from '../../../../hooks/useAppointmentBookingConfig/useAppointmentBookingConfig';
import { MultipleChoiceSelect, Option } from '../../../shared/MultipleChoiceSelect/MultipleChoiceSelect';
import TextField from '../../../shared/TextField/TextField';
import { useAppointmentSlotsConfig } from '../../../../hooks/useAppointmentSlotsConfig/useAppointmentSlotsConfig';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { useCurrentOrganisationDetails } from '../../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { MAX_SELF_BOOKING_APPOINTMENT_DAYS_EMIS } from '../../../../util/constants';
import { AppointmentSlotsConfig } from '../../../../domain/Appointments';

function buildOptions(slotTypes: AppointmentSlotsConfig['slotTypes'] | undefined): Option<string>[] {
  return (
    slotTypes?.map((slotType) => ({
      value: slotType.id,
      label: slotType.name,
    })) ?? []
  );
}

export function AppointmentBooking() {
  const form = useFormContext();

  const errors = form.formState.errors;

  const addInvitationLink = form.watch('appointmentsAddInvitationLink');

  const { config, loading } = useAppointmentBookingConfig();
  const { enabled } = config ?? { enabled: false };

  const {
    loading: appointmentSlotsConfigLoading,
    config: appointmentSlotsConfig,
    error: appointmentSlotsError,
    refetch: appointmentSlotsRefetch,
  } = useAppointmentSlotsConfig(!!addInvitationLink);

  const { organisation } = useCurrentOrganisationDetails();

  const options: Option<string>[] = useMemo(
    () => buildOptions(appointmentSlotsConfig?.slotTypes),
    [appointmentSlotsConfig?.slotTypes],
  );

  return (
    <Box sx={{ '& .MuiAlert-root': { color: 'text.primary' } }}>
      <AppointmentBookingCheckbox disabled={!enabled} opening={appointmentSlotsConfigLoading} />

      {!loading && !enabled && (
        <Box marginBottom={2}>
          <Alert severity="info">
            <Typography marginBottom={0.75}>
              Self-book is currently not available for your organisation — contact{' '}
              <Link underline="always" target="_blank" href="mailto:support@abtrace.co">
                support@abtrace.co
              </Link>{' '}
              for more information.
            </Typography>
            <Typography>
              Patients clicking this link will be taken to a web form where they can detail their availability. This
              will then be emailed to your practice.
            </Typography>
          </Alert>
        </Box>
      )}

      <Collapse in={addInvitationLink && !appointmentSlotsConfigLoading} timeout="auto" unmountOnExit={true}>
        {(() => {
          if (appointmentSlotsError) {
            return (
              <Box marginBottom={2}>
                <Box display="flex" flexDirection="column" gap={3}>
                  <Alert severity="error">
                    <Typography mb={1}>There was an error loading appointment slot configuration.</Typography>
                    <Typography>
                      You can retry or contact{' '}
                      <Link underline="always" href="mailto:support@abtrace.co" target="_blank">
                        support@abtrace.co
                      </Link>{' '}
                      if the issue persists.
                    </Typography>
                  </Alert>

                  <Button
                    startIcon={<Refresh />}
                    onClick={() => appointmentSlotsRefetch()}
                    sx={{ alignSelf: 'center', marginTop: -2, color: 'text.primary' }}
                  >
                    Click here to retry
                  </Button>
                </Box>
              </Box>
            );
          }

          if (appointmentSlotsConfigLoading) return null;

          return (
            <Stack gap={1.5} marginLeft="27px" marginBottom={2}>
              <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ marginBottom: 1 }}>
                  Allow patients to self-book an appointment within
                </FormLabel>
                <FormControlLabel
                  sx={{ marginLeft: 0 }}
                  control={
                    <TextField
                      name="appointmentsBookableWithinDays"
                      label="Days"
                      type="number"
                      control={form.control}
                      error={errors.appointmentsBookableWithinDays as FieldError}
                      rules={{
                        required: 'Days are required',
                        min: {
                          value: 1,
                          message:
                            organisation.ehr === 'emis'
                              ? `Must be between 1 and ${MAX_SELF_BOOKING_APPOINTMENT_DAYS_EMIS} days, inclusive`
                              : 'Must be ≥ 1',
                        },
                        max:
                          organisation.ehr === 'emis'
                            ? {
                                value: MAX_SELF_BOOKING_APPOINTMENT_DAYS_EMIS,
                                message: `Must be between 1 and ${MAX_SELF_BOOKING_APPOINTMENT_DAYS_EMIS} days, inclusive`,
                              }
                            : undefined,
                      }}
                      helperText={(errors.appointmentsBookableWithinDays as FieldError)?.message}
                      size="small"
                      slotProps={{
                        htmlInput: { sx: { width: '60px' }, min: 1 },
                      }}
                    />
                  }
                  label={
                    <Typography
                      component="span"
                      sx={{
                        marginLeft: 1,
                      }}
                    >
                      days after receiving the invitation
                    </Typography>
                  }
                />
              </FormControl>

              <Controller
                name="appointmentsSlotTypes"
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <FormControl sx={{ flex: 'auto' }}>
                    <MultipleChoiceSelect
                      label="Slot types"
                      placeholder="Slot types"
                      values={value}
                      options={options}
                      noOptionsText="No slots available"
                      deletedOptionText="This slot type no longer seems to exist"
                      loading={appointmentSlotsConfigLoading}
                      onChange={onChange}
                      getInputParams={(params) => ({
                        ...params,
                        error: !!(errors.appointmentsSlotTypes as FieldError)?.type,
                        helperText: (errors.appointmentsSlotTypes as FieldError)?.message,
                      })}
                      slotProps={{
                        paper: {
                          sx: {
                            border: '1px solid',
                            boxShadow: 1,
                          },
                        },
                      }}
                    />
                  </FormControl>
                )}
                rules={{
                  required: 'Slot types are required',
                }}
                defaultValue={[]}
              />

              <TextField
                name={'appointmentsDescription'}
                control={form.control}
                error={errors.appointmentsDescription as FieldError}
                rules={{
                  required: 'Description is required',
                }}
                label="Description"
                required
                variant="outlined"
                size="small"
                fullWidth
                helperText={(errors.appointmentsDescription as FieldError)?.message ?? 'e.g., "Routine tests"'}
              />
            </Stack>
          );
        })()}
      </Collapse>
    </Box>
  );
}
