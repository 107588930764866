import { ORGANISATION_DETAILS_QUERY_KEY, ORGANISATION_DETAILS_STALE_TIME } from '../../util/queries';
import { UseQueryResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { loadOrganisationDetails } from '../../apis/app/AppApi';
import { OrganisationDetails } from '../../domain/Organisation';

interface UseOrganisationDetailsResult {
  organisations?: Record<string, OrganisationDetails | null>;
  loading: boolean;
  refreshOrganisation: (organisation: string) => void;
}

/**
 * Resolves the organisation details for a given list of organisations.
 * The details are cached for each individual organisation, to avoid refetches.
 * For example, if you do:
 *
 *   useOrganisationDetails(['T11111', 'T22222', 'T33333'])
 *
 * and then later:
 *
 *   useOrganisationDetails(['T11111', 'T22222', 'T44444'])
 *
 * the second time will only trigger a request for organisation T44444.
 *
 * The parameter can also be another React Query that fetches the list of
 * organisations. For example:
 *
 *   const { loading } = useOrganisationDetails(
 *     useQuery({
 *       queryKey: [USER_APPLICATIONS_QUERY_KEY],
 *       queryFn: () => Api.loadUserApplications(),
 *     })
 *   )
 *
 * In this scenario, `loading` will be `true` while the source query is loading
 */
export function useOrganisationDetails<T>(
  organisationsOrQuery: string[] | UseQueryResult<T[], unknown>,
  transform?: (queryResult: T) => string,
): UseOrganisationDetailsResult {
  const queryClient = useQueryClient();

  const [organisationsLoading, organisations] = Array.isArray(organisationsOrQuery)
    ? [false, organisationsOrQuery]
    : [
        organisationsOrQuery.isLoading || organisationsOrQuery.isFetching,
        transform ? organisationsOrQuery.data?.map(transform) : (organisationsOrQuery.data as string[] | undefined),
      ];

  // Disable the query until the list of organisations finishes loading
  const enabled = !!organisations && organisations.length > 0;

  const { data, isLoading, refetch } = useQuery({
    queryKey: [ORGANISATION_DETAILS_QUERY_KEY, organisations],
    enabled,
    queryFn: async () => {
      // Find out which organisations are already cached
      const knownOrganisationDetails = new Map(
        organisations!
          .flatMap((organisation) =>
            queryClient.getQueryData<OrganisationDetails>([ORGANISATION_DETAILS_QUERY_KEY, organisation]),
          )
          .filter((cachedOrganisationDetails) => !!cachedOrganisationDetails)
          .map((cachedOrganisationDetails) => [
            (cachedOrganisationDetails as OrganisationDetails).organisation as string,
            cachedOrganisationDetails as OrganisationDetails,
          ]),
      );

      // These are the organisations not cached (without duplicates)
      const unknownOrganisations = Array.from(
        new Set(organisations!.filter((org) => !knownOrganisationDetails.has(org))),
      );

      if (unknownOrganisations.length > 0) {
        let unknownOrganisationDetails: Record<string, OrganisationDetails | null>;
        try {
          unknownOrganisationDetails = await loadOrganisationDetails(unknownOrganisations);

          // Cache the new organisations
          for (const [org, details] of Object.entries(unknownOrganisationDetails)) {
            queryClient.setQueryData([ORGANISATION_DETAILS_QUERY_KEY, org], details);
          }
        } catch {
          // If an error occurs, carry on with empty details
          unknownOrganisationDetails = Object.fromEntries(unknownOrganisations.map((org) => [org, null]));
        }

        return {
          ...Object.fromEntries(knownOrganisationDetails),
          ...unknownOrganisationDetails,
        };
      }

      return Object.fromEntries(knownOrganisationDetails);
    },

    staleTime: ORGANISATION_DETAILS_STALE_TIME,
    retry: false,
  });

  return {
    organisations: data,
    loading: organisationsLoading || (enabled && isLoading),
    refreshOrganisation: async (organisation: string) => {
      // Invalidate all queries containing this organisation
      const toInvalidate = queryClient
        .getQueriesData({
          predicate: ({ queryKey }) =>
            queryKey[0] === ORGANISATION_DETAILS_QUERY_KEY &&
            !!queryKey[1] &&
            (queryKey[1] === organisation || (queryKey[1] as string[]).includes(organisation)),
        })
        .map(([queryKey]) => queryKey);

      for (const query of toInvalidate) {
        queryClient.removeQueries({ queryKey: query });
      }

      return refetch();
    },
  };
}
