import { Amplify } from 'aws-amplify';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { Hub, HubCapsule } from 'aws-amplify/utils';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import ConfigContext from '../../../context/ConfigContext';
import UserContext from '../../../context/UserContext';
import { parseIdToken, User } from '../../../domain/User';
import { traceIdInterceptor } from '../../../services/traceIdInterceptor';
import {
  APP_API_NAME,
  APPOINTMENT_BOOKING_API_NAME,
  AUTH_API_NAME,
  MESSAGING_API_NAME,
  PATIENT_FACING_API_NAME,
  QOFOPT_API_NAME,
  SCHEDULED_MESSAGING_API_NAME,
  WEBSITE_API_NAME,
} from '../../../util/constants';

export default function AmplifyProvider({ children }: React.PropsWithChildren<unknown>) {
  const [currentUser, setCurrentUser] = useState<null | User>(null);
  const [loaded, setLoaded] = useState(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const config = useContext(ConfigContext);

  const updateIsVerified = (value: boolean) => {
    setIsVerified(value);
  };

  async function updateCurrentUser(user?: User) {
    if (user) {
      setCurrentUser(user);
      setLoaded(true);
      return;
    }

    try {
      const awsUser = await getCurrentUser();
      const session = await fetchAuthSession();

      const idToken = session.tokens?.idToken;

      if (!idToken) {
        throw new Error('Invalid ID Token');
      }

      const jwtPayload = idToken.payload;

      const newUser = {
        email: jwtPayload.email as string,
        firstName: (jwtPayload.given_name as string) ?? '',
        lastName: (jwtPayload.family_name as string) ?? '',
        role: (jwtPayload['custom:role'] as string) ?? '',
        organisations: parseIdToken(idToken.toString()!),
        userId: awsUser.username,
        superUser: jwtPayload['custom:super_user'] === 'true',
        supportUser: jwtPayload['custom:support_user'] === 'true',
      };

      setCurrentUser(newUser);
      setLoaded(true);
    } catch {
      setCurrentUser(null);
      setLoaded(true);
    }
  }

  const onHubCapsule = (capsule: HubCapsule<string, { event: string }>) => {
    const { channel, payload } = capsule;
    if (channel === 'auth' && payload.event !== 'signIn') {
      updateCurrentUser(undefined);
    }
  };

  useEffect(() => {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: config.amplify.auth.userPoolId,
          userPoolClientId: config.amplify.auth.audience,
        },
      },
      API: {
        REST: {
          [WEBSITE_API_NAME]: {
            endpoint: config.amplify.endpoints.website,
            region: config.amplify.region,
          },
          [AUTH_API_NAME]: {
            endpoint: config.amplify.endpoints.auth,
            region: config.amplify.region,
          },
          [APP_API_NAME]: {
            endpoint: config.amplify.endpoints.app,
            region: config.amplify.region,
          },
          [QOFOPT_API_NAME]: {
            endpoint: config.amplify.endpoints.qofopt,
            region: config.amplify.region,
          },
          [MESSAGING_API_NAME]: {
            endpoint: config.amplify.endpoints.messaging,
            region: config.amplify.region,
          },
          [SCHEDULED_MESSAGING_API_NAME]: {
            endpoint: config.amplify.endpoints.scheduledMessaging,
            region: config.amplify.region,
          },
          [APPOINTMENT_BOOKING_API_NAME]: {
            endpoint: config.amplify.endpoints.appointmentBooking,
            region: config.amplify.region,
          },
          [PATIENT_FACING_API_NAME]: {
            endpoint: config.amplify.endpoints.patientFacing,
            region: config.amplify.region,
          },
        },
      },
    });

    axios.interceptors.request.use(traceIdInterceptor);

    updateCurrentUser(undefined);
    Hub.listen('auth', onHubCapsule);
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: currentUser,
        updateCurrentUser,
        updateIsVerified,
        isVerified,
        loaded: loaded,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
