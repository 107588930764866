import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import { resetPassword } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { FieldError, FieldValues, useForm } from 'react-hook-form';
import EmailField from '../../../shared/EmailField/EmailField';
import { URLS } from '../../../../util/urls';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';

interface Props {
  onCodeRequested: (email: string) => void;
}

export default function RequestCodeForm({ onCodeRequested }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const updateErrorMessage = (errorMessage: string) => {
    dispatch(enqueueErrorNotification(errorMessage));
  };

  async function sendResetCode({ email }: FieldValues) {
    setSubmitting(true);

    try {
      await resetPassword({ username: email });

      onCodeRequested(email);
    } catch (error) {
      updateErrorMessage(
        `Unable to send the account recovery code: ${error instanceof Error ? error.message : new String(error)}`,
      );
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(sendResetCode)}>
      <FormControl sx={{ rowGap: '24px', width: '290px' }}>
        <>
          <EmailField
            id="email"
            name={'email'}
            control={control}
            error={errors.email as FieldError}
            rules={{
              required: 'E-mail is required',
              invalid: 'Please enter a valid e-mail',
            }}
            label="E-mail"
            autoFocus={true}
            required
          />

          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Link component={RouterLink} to={URLS.LOGIN}>
              <Button color="primary">Back</Button>
            </Link>

            <Button
              type="submit"
              data-testid="send-code"
              variant="contained"
              color="primary"
              loading={submitting}
              onClick={handleSubmit(sendResetCode)}
            >
              Send code
            </Button>
          </Stack>
        </>
      </FormControl>
    </form>
  );
}
