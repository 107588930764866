import React, { useContext } from 'react';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router';
import UserContext from '../../../context/UserContext';
import { URLS } from '../../../util/urls';
import PasswordReset from '../../../pages/resetpassword/ResetPasswordPage';
import LoginPage from '../../../pages/login/LoginPage';
import HomePage from '../../../pages/home/HomePage';
import OrganisationPage from '../../../pages/organisations/OrganisationPage';
import RegistrationPage from '../../../pages/registration/RegistrationPage';
import ApplicationsPage from '../../../pages/applications/ApplicationsPage';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import AccountVerificationPage from '../../../pages/accountverification/AccountVerificationPage';
import { PostVerification } from '../../../pages/PostVerification';
import { Overview } from '../../../pages/overview';

const LoggedOutRoutes: React.FC<{ isVerified: boolean }> = ({ isVerified }) => {
  return (
    <RouterRoutes>
      <Route path={URLS.LOGIN} element={<LoginPage />} />
      <Route path={URLS.REGISTER} element={<RegistrationPage />} />
      <Route path={URLS.PASSWORD_RESET} element={<PasswordReset />} />
      <Route path={URLS.ACCOUNT_VERIFICATION} element={<AccountVerificationPage />} />
      {isVerified && <Route path={URLS.POST_VERIFICATION} element={<PostVerification />} />}
      <Route path="*" element={<LoginPage />} />
    </RouterRoutes>
  );
};

const LoggedInRoutes: React.FC = () => {
  return (
    <RouterRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path={URLS.LOGIN} element={<Navigate to="/" replace />} />
      <Route path="/organisations/:name" element={<OrganisationPage />} />
      <Route path="/applications" element={<ApplicationsPage />} />
      <Route path="/abtrace" element={<Overview />} />
    </RouterRoutes>
  );
};

export default function Routes() {
  const { user, loaded, isVerified } = useContext(UserContext);
  const isAuthenticated = user && user.email;

  return (
    <LoadingSection loading={!loaded}>
      {isAuthenticated ? <LoggedInRoutes /> : <LoggedOutRoutes isVerified={isVerified} />}
    </LoadingSection>
  );
}
