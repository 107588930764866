import Box from '@mui/system/Box';
import React, { useContext, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import UserContext from '../../../context/UserContext';
import { enqueueErrorNotification } from '../../../redux/reducers/notificationsReducer';
import Button from '@mui/material/Button';
import { EXPIRY_DATE_AFTER_CLEARING } from '../../../util/constants';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch } from 'react-redux';
import { useCurrentOrganisationDetails } from '../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const displayDateFormat = 'DD-MMM-YYYY';
const systemDateFormat = 'YYYY-MM-DD';

dayjs.extend(customParseFormat);

function InputAdornmentWithCircularProgress() {
  return (
    <InputAdornment position="end">
      <CircularProgress size="28px" color="inherit" />
    </InputAdornment>
  );
}

export function ContractExpiryDate() {
  const [value, setValue] = useState<Dayjs | null>(null);

  const [updating, setUpdating] = useState<boolean>(false);
  const { user } = useContext(UserContext);
  const canEdit = user?.superUser || user?.supportUser;

  const { organisation, loading: isLoadingOrgDetails, updateContractExpiryDate } = useCurrentOrganisationDetails();

  const dispatch = useDispatch();

  function parseValidDateValueOrNull(dateStr: string | undefined): Dayjs | null {
    if (dateStr) {
      const date = dayjs(dateStr, systemDateFormat);

      if (date.isValid() && !date.isSame(EXPIRY_DATE_AFTER_CLEARING)) {
        return date;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  useEffect(() => {
    setValue(parseValidDateValueOrNull(organisation?.contractExpiryDate));
  }, [organisation?.contractExpiryDate]);

  const handleChange = async (newDate: Dayjs | null) => {
    const internalDate = newDate ?? dayjs(EXPIRY_DATE_AFTER_CLEARING);

    setUpdating(true);
    try {
      await updateContractExpiryDate(internalDate.format(systemDateFormat));
      setValue(newDate);
    } catch (error) {
      dispatch(enqueueErrorNotification('Unable to update expiry date', error));
    } finally {
      setUpdating(false);
    }
  };

  const isExpiryDateSet = !!value;

  return (
    <LoadingSection loading={isLoadingOrgDetails}>
      <Box mt={2}>
        {!canEdit ? (
          <Typography variant="h6">{isExpiryDateSet ? value!.format(displayDateFormat) : 'Not set'}</Typography>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format={displayDateFormat}
                label="Expiry Date"
                value={value}
                loading={updating}
                disabled={updating}
                onChange={handleChange}
                minDate={dayjs()}
                maxDate={dayjs(EXPIRY_DATE_AFTER_CLEARING)}
                slots={{
                  inputAdornment: updating ? InputAdornmentWithCircularProgress : InputAdornment,
                }}
              />
            </LocalizationProvider>
            <Box pl={2}>
              <Button variant="outlined" disabled={!isExpiryDateSet} onClick={() => handleChange(null)}>
                Clear Date
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </LoadingSection>
  );
}
