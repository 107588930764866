import { z } from 'zod';

const bulkMessagingReportSummarySchema = z.object({
  patientsSelected: z.number(),
  emailsSent: z.number().or(z.literal('NOT_APPLICABLE')),
  emailsDeliveryFailed: z.number().or(z.literal('NOT_APPLICABLE')),
  smsSent: z.number().or(z.literal('NOT_APPLICABLE')),
  smsDelivered: z.number().or(z.literal('NOT_APPLICABLE')),
  smsDeliveryFailed: z.number().or(z.literal('NOT_APPLICABLE')),
  notContacted: z.number(),
  patientRecordUpdateErrors: z.number(),
  hadPatientsOpenInTpp: z.boolean(),
});

const bulkMessageReportOverviewSchema = z.object({
  reportId: z.string(),
  organisation: z.string(),
  sender: z.string(),
  timestamp: z.string().pipe(z.coerce.date()),
});

export type BulkMessageReportOverview = z.infer<typeof bulkMessageReportOverviewSchema>;

const getBulkMessageReportsResponse = z.object({
  reports: z.array(bulkMessageReportOverviewSchema),
  cursor: z.string().nullish(),
});

export type GetBulkMessageReportsResponse = z.infer<typeof getBulkMessageReportsResponse>;

export function parseGetBulkMessageReportsResponse(response: unknown): GetBulkMessageReportsResponse {
  return getBulkMessageReportsResponse.parse(response);
}

const getBulkMessageReportResponse = z.object({
  reportId: z.string(),
  timestamp: z.string().pipe(z.coerce.date()),
  details: z.array(
    z.object({
      nhsNumber: z.string(),
      emailStatus: z
        .string()
        .nullish()
        .transform((status) => status ?? null),
      smsStatus: z
        .string()
        .nullish()
        .transform((status) => status ?? null),
      notes: z
        .string()
        .nullish()
        .transform((notes) => notes ?? null),
    }),
  ),
  summary: bulkMessagingReportSummarySchema,
});

export type GetBulkMessageReportResponse = z.infer<typeof getBulkMessageReportResponse>;

export function parseGetBulkMessageReportResponse(response: unknown): GetBulkMessageReportResponse {
  return getBulkMessageReportResponse.parse(response);
}
