import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { RulesList } from '../RulesList/RulesList';
import { RuleDetails } from '../RuleDetails/RuleDetails';
import RulesContext from '../../../../context/RulesContext/RulesContext';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification } from '../../../../redux/reducers/notificationsReducer';
import { SectionSpinner } from '../../../shared/LoadingSection/LoadingSection';

export function RulesSection() {
  const { loading, error } = useContext(RulesContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      dispatch(enqueueErrorNotification('Unable to load scheduled messaging rules', error));
    }
  }, [error]);

  if (loading) {
    return <SectionSpinner mb={3} />;
  }

  return (
    <>
      <Typography variant="h6" fontWeight="400" mb={-1}>
        Recall management
      </Typography>

      <Box display="flex" gap={3}>
        <RulesList />
        <RuleDetails />
      </Box>
    </>
  );
}
