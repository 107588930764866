export default function groupBy<T extends Record<string, string | number | symbol>, K extends keyof T>(
  array: T[],
  key: K,
): Record<T[K], T[]> {
  const result = {} as Record<T[K], T[]>;

  array.forEach((item) => {
    const value = item[key];

    result[value] ??= [] as T[];
    result[value].push(item);
  });

  return result;
}
