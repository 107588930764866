import { useQuery } from '@tanstack/react-query';
import {
  SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_KEY,
  SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_STALE_TIME,
} from '../../util/queries';
import { RuleExecutionReport } from '../../domain/ScheduledMessaging';
import { downloadReport, loadRuleExecution } from '../../apis/scheduledMessaging/ScheduledMessagingApi';

interface UseRuleExecutionResult {
  report?: RuleExecutionReport;
  downloadCsv?: () => Promise<{
    fileName: string;
    content: string;
  }>;
  loading: boolean;
  error: unknown;
}

export function useRuleExecution(executionId: string): UseRuleExecutionResult {
  const [, organisation, ruleId, date] = executionId.match(/(.+)#(.+)#(.+)/)!;

  const { data, isPending, isLoading, error } = useQuery({
    queryKey: [SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_KEY, organisation, ruleId, date],
    queryFn: () => loadRuleExecution(organisation, ruleId, date),
    staleTime: SCHEDULED_MESSAGING_RULE_EXECUTION_HISTORY_QUERY_STALE_TIME,
    retry: false,
  });

  const downloadCsv = async () => {
    const content = await downloadReport(executionId);
    return {
      fileName: `report_${date}.csv`,
      content,
    };
  };

  return {
    report: data,
    downloadCsv: data ? downloadCsv : undefined,
    loading: isPending || isLoading,
    error,
  };
}
