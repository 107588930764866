import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Link from '@mui/material/Link';
import { SCHEDULED_MESSAGING_MORE_INFO_URL } from '../../../../domain/ScheduledMessaging';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface Props {
  enabled: boolean;
  available: boolean;
  onChange: (checked: boolean) => void;
}

export const AvailabilityCheckbox: React.FC<Props> = ({ enabled, available, onChange }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <FormControlLabel
        label="Practice admins can configure automated recalls"
        control={
          <Checkbox
            checked={available}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
            disabled={!enabled}
          />
        }
        sx={{ mr: 0 }}
      />

      <Link
        href={SCHEDULED_MESSAGING_MORE_INFO_URL}
        target="_blank"
        underline="always"
        display="flex"
        gap={1}
        alignItems="center"
      >
        <Typography>More information</Typography>
        <OpenInNewIcon sx={{ fontSize: '16px' }} />
      </Link>
    </Box>
  );
};
