import { AxiosInstance } from 'axios';
import { Clinicians, FrontendDefinitions, OrganisationDetails } from '../../domain/Organisation';
import { PatientTaskCategory } from '../../domain/PatientFacing';
import { callApi, getApiClient, resetApiClient } from '../../util/api';
import { APP_API_NAME } from '../../util/constants';

export type Ehr = 'emis' | 'tpp';

function client(): AxiosInstance {
  return getApiClient(APP_API_NAME);
}

export async function loadOrganisationDetails(
  organisations: string[],
): Promise<Record<string, OrganisationDetails | null>> {
  return callApi(client().get, '/organisations', undefined, {
    params: {
      ids: organisations.join(','),
    },
  }) as Promise<Record<string, OrganisationDetails | null>>;
}

export async function loadFrontendDefinitions(organisation: string): Promise<FrontendDefinitions> {
  return callApi(client().get, `/frontenddefinitions/${organisation}`) as Promise<FrontendDefinitions>;
}

export async function loadClinicians(organisation: string, ehr: string): Promise<Clinicians> {
  return callApi(client().get, `/clinicians/${ehr}/${organisation}`) as Promise<Clinicians>;
}

export async function loadPatientTaskCategoriesList(
  organisation: string,
  ehr: string,
): Promise<{ data: PatientTaskCategory[] }> {
  // nullOnError is false because if there is some sort of error, it's an actual error
  // (not like 404 because the organisation is not yet set)
  return callApi(client().get, `/patient-tasks/${ehr}/${organisation}/categories`) as Promise<{
    data: PatientTaskCategory[];
  }>;
}

export const updateContractExpiryDate = async (organisationId: string, contractExpiryDate: string) => {
  return callApi(client().patch, `/organisations/${organisationId}`, {
    contract_expiry_date: contractExpiryDate,
  });
};

export const updateEhr = async (organisationId: string, ehr: Ehr) => {
  return callApi(client().patch, `/organisations/${organisationId}`, {
    ehr: ehr,
  });
};

export const updateOnSiteEncryption = async (organisationId: string, onSiteEncryption: boolean) => {
  return callApi(client().patch, `/organisations/${organisationId}`, {
    on_site_encryption: onSiteEncryption,
  });
};

export const updatePopulationTabBeta = async (organisationId: string, populationTabBetaVisible: boolean) => {
  return callApi(client().patch, `/organisations/${organisationId}`, {
    population_tab_beta_visible: populationTabBetaVisible,
  });
};

export const searchOrganisation = async (filterString: string): Promise<Record<string, { name: string }>> => {
  return callApi(client().get, '/organisations', undefined, {
    params: {
      filterstring: filterString,
    },
  }) as Promise<Record<string, { name: string }>>;
};

export function resetClient() {
  resetApiClient(APP_API_NAME);
}
