import React, { useEffect, useState } from 'react';
import * as Api from '../../apis/website/Api';
import { OrganisationMember } from '../../domain/Organisation';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification } from '../../redux/reducers/notificationsReducer';

export interface OrganisationUsersContextValue {
  users: OrganisationMember[];
  setUsers: (users: OrganisationMember[]) => void;
  loading: boolean;
  refresh: () => void;
}

const context = React.createContext<OrganisationUsersContextValue>({
  users: [],
  setUsers: () => {},
  loading: true,
  refresh: () => {},
});

interface Props {
  organisation: string;
  children: React.ReactNode;
}

export function OrganisationUsersContextProvider({ organisation, children }: Props) {
  const [users, setUsers] = useState<OrganisationMember[]>([]);
  const [loading, setLoading] = useState(true);
  const [refreshDep, setRefreshDep] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    setUsers([]);
    Api.loadUsers(organisation)
      .then((newUsers) => {
        setUsers(newUsers);
      })
      .catch((error) => {
        dispatch(enqueueErrorNotification(`Unable to load users in organisation ${organisation}`, error));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [organisation, refreshDep]);

  const refresh = () => setRefreshDep(!refreshDep);

  const value = { users, setUsers, loading, refresh };
  return <context.Provider value={value}>{children}</context.Provider>;
}

export default context;
