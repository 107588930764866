import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { ConfirmationDialog, ConfirmationDialogConfig } from '../../shared/ConfirmationDialog/ConfirmationDialog';
import { LoadingSection } from '../../shared/LoadingSection/LoadingSection';
import { useDialog } from '../../../hooks/useDialog/useDialog';
import { useCurrentOrganisationDetails } from '../../../hooks/useCurrentOrganisationDetails/useCurrentOrganisationDetails';
import { enqueueErrorNotification } from '../../../redux/reducers/notificationsReducer';

import type { Ehr } from '../../../apis/app/AppApi';

interface Option { label: string; id: Ehr | null }

const OPTIONS: Option[] = [
  { label: 'EMIS', id: 'emis' },
  { label: 'TPP', id: 'tpp' },
  { label: 'Not set', id: null },
];

export function EhrSelector() {
  const [currentEhr, setEhr] = useState<Ehr | null>(null);

  const [updating, setUpdating] = useState<boolean>(false);

  const { organisation, loading, updateEhr } = useCurrentOrganisationDetails();

  const dispatch = useDispatch();

  const { open, connector } = useDialog<ConfirmationDialogConfig>();

  useEffect(() => {
    setEhr(organisation?.ehr as Ehr);
  }, [organisation?.ehr]);

  const handleChange = async (option: Option) => {
    const ehr = option.id as Ehr;

    const partialText = organisation.ehr
      ? `change ${organisation.organisation}'s EHR provider from ${organisation.ehr?.toUpperCase()}`
      : `set ${organisation.organisation}'s EHR provider`;
    open({
      title: 'Change EHR',
      text: `Are you sure you want to ${partialText} to ${ehr.toUpperCase()}? Be careful with accepting this change. Service failures can result from an incorrect setting.`,
    }).then(async () => {
      setUpdating(true);
      try {
        await updateEhr(ehr);
        setEhr(ehr);
      } catch (error) {
        dispatch(enqueueErrorNotification('Unable to update EHR', error));
      } finally {
        setUpdating(false);
        connector.close();
      }
    });
  };

  const filterOptions = (options: Option[]) => {
    return options.filter((option) => option.id);
  };

  return (
    <LoadingSection loading={loading}>
      <Autocomplete
        id="ehr"
        sx={{ width: 130, mt: 2 }}
        options={OPTIONS}
        value={OPTIONS.find((obj) => obj.id == currentEhr)}
        filterOptions={filterOptions}
        onChange={(_event, value) => handleChange(value as Option)}
        forcePopupIcon
        renderInput={(params) => <TextField {...params} />}
        autoComplete
        disableClearable
        disabled={updating}
      />
      <ConfirmationDialog connector={connector} />
    </LoadingSection>
  );
}
