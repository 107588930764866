import React, { useContext } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useDispatch } from 'react-redux';
import { enqueueErrorNotification, enqueueInfoNotification } from '../../redux/reducers/notificationsReducer';
import { useQueryClient } from '@tanstack/react-query';
import { QUERY_STRING_ORGANISATION_DETAILS } from '../../util/constants';
import { APPOINTMENTS_QUERY_KEY } from '../../util/queries';
import { OrgDetails } from './helpers';
import { updateAppointmentConfig } from '../../apis/appointments/AppointmentsApi';
import UserContext from '../../context/UserContext';

interface Props {
  organisation: string;
  enabled: boolean;
  email: string | null;
}

export const AppointmentBookingEnabledCheckbox: React.FC<Props> = ({ organisation, email, enabled }) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { user } = useContext(UserContext);
  const canEdit = user?.superUser;

  const isEditable = canEdit;

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = event.target.checked;
    try {
      await updateAppointmentConfig(organisation, updatedValue, email);

      queryClient.invalidateQueries({ queryKey: [APPOINTMENTS_QUERY_KEY, organisation] });
      queryClient.setQueryData([QUERY_STRING_ORGANISATION_DETAILS], (previousConfig: OrgDetails[]) => {
        return previousConfig.map((org: OrgDetails) =>
          org.id === organisation ? { ...org, enabled: updatedValue } : org,
        );
      });
      dispatch(enqueueInfoNotification('Organisation configuration updated'));
    } catch (err) {
      dispatch(enqueueErrorNotification('Unable to save the configuration', err));
    }
  };

  return <Checkbox disabled={!isEditable} checked={enabled} onChange={onChange} />;
};
