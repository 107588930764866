import Typography from '@mui/material/Typography';
import React from 'react';

interface Props {
  isError: boolean;
}

export const colourRed = '#d32f2f';

export const PasswordRequirement: React.FC<Props> = ({ isError }) => {
  const textColour = isError ? colourRed : 'black';

  return (
    <div>
      <Typography color={textColour} variant="body2">
        {' '}
        Your password must contain:
      </Typography>
      <ul>
        <li>
          <Typography color={textColour} variant="body2">
            At least 8 characters
          </Typography>
        </li>
        <li>
          <Typography color={textColour} variant="body2">
            At least 1 uppercase letter, 1 lowercase letter, 1 digit and 1 symbol
          </Typography>
        </li>
      </ul>
    </div>
  );
};
