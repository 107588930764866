import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

interface Props {
  title: string;
  children: ReactNode;
}

export default function SidePanel({ title, children }: Props) {
  return (
    <>
      <Box m={2} mt={4}>
        <Typography variant="h6" component="div" sx={(theme) => ({ color: theme.palette.grey['700'] })}>
          {title}
        </Typography>
        <Divider />
        {children}
      </Box>
    </>
  );
}
