import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import React from 'react';
import { Link } from 'react-router';
import { URLS } from '../../util/urls';
import DownloadsPanel from '../../components/downloads/DownloadsPanel';

export const PostVerification: React.FC = () => {
  return (
    <Stack direction="column" alignItems="center" mt={16}>
      <Stack direction="column" alignItems="center" gap={2}>
        <Typography variant="h4">Thank you for verifying your account</Typography>
        <Typography variant="h5">You are now able to log in, please select an option below:</Typography>
      </Stack>
      <Stack direction="column" alignItems="center" gap={1.5} mt={8}>
        <Box>
          <Button variant="contained" onClick={() => window.open('abtrace://')}>
            Launch the Abtrace tool
          </Button>
        </Box>
        <Link to={URLS.LOGIN}>
          <Button>Manage your organisation</Button>
        </Link>
      </Stack>
      <DownloadsPanel />
    </Stack>
  );
};
